import * as React from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import likeIcon from "../../assets/like.svg";
import ScaleLoader from "react-spinners/ScaleLoader";
import {useWeb3Functions} from "../../services/useWeb3Functions";
import {setClaimStatus} from "../../redux/reducer/prizeCart";

export const Distribution = () => {
    const dispatch = useAppDispatch();
    const claimStatus = useAppSelector(state => state.prizeCart.claimStatus);
    const web3Functions = useWeb3Functions();
    return (
        <button onClick={() => {
            web3Functions.claimReward();
            dispatch(setClaimStatus(true));
        }} type="button"
                disabled={claimStatus}
                className={`bg-[#C8A323] text-white flex flex-row gap-x-2 w-full h-14 items-center text-lg rounded-2xl text-center justify-center font-semibold`}>
            <img src={likeIcon} className={'w-7'} alt={'flash-icon'}/> Confirm Rewards
            <ScaleLoader color={'#fff'} loading={claimStatus} height={22} width={2}/>
        </button>
    )
}