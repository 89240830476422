import React from "react";
import "./App.css";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Home} from "./Pages/Home";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useAppSelector} from "./hooks";

function App() {
    const widgetStatus = useAppSelector(state => state.widget.stats);
    return (
        <>
            <ToastContainer
                autoClose={2000}
                closeButton={false}
                hideProgressBar={true}
                style={{"top": "5em", "right": "3em"}}
                position={`${widgetStatus ? 'top-left' : 'top-right'}`}
            />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
