import tgIcon from "../../assets/social/_Telegram.svg";
import dcIcon from "../../assets/social/_Discord.svg";
import twIcon from "../../assets/social/_Twitter.svg";
import wpIcon from "../../assets/social/Document.svg";
import {twitter_link, discord_link, telegram_link, whitepaper_link} from "../../services/link.config";

export const SocialTab = () => {
    return (
        <div className={`block sm:hidden flex flex-row items-center fixed bottom-0 left-0 right-0 justify-center bg-[#17111A] py-3`}>
            <div className={'px-3 flex flex-row gap-x-2'}>
                <div
                    className={'flex flex-row items-center justify-end sm:grid gap-x-1 2xl:gap-x-2 grid-cols-2'}>
                    <a href={whitepaper_link}
                       className={'flex flex-row items-center justify-center gap-x-1 border-2 border-zinc-700 bg-[#1F1822] rounded-xl py-3  max-w-[150px] w-[150px]'}
                       target={"_blank"}>
                        <img src={wpIcon} alt={'wp-icon'} className={'w-7'}/>
                        <span className={'text-white font-semibold text-base 2xl:text-lg'}>Whitepaper</span>
                    </a>
                    <div
                        className={'flex flex-row justify-center gap-x-5 border-2 border-zinc-700 bg-[#1F1822] rounded-xl py-2.5 max-w-[150px] w-[150px]'}>
                        <a href={telegram_link} target={"_blank"}><img src={tgIcon} alt={'tg-icon'} className={'w-8'}/>
                        </a>
                        <a href={discord_link} target={"_blank"}><img src={dcIcon} alt={'dc-icon'} className={'w-8'}/>
                        </a>
                        <a href={twitter_link} target={"_blank"}><img src={twIcon} alt={'tw-icon'} className={'w-8'}/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}