import {createSlice} from '@reduxjs/toolkit'

interface prizeCartState {
    state: boolean,
    approveStatus: boolean,
    claimStatus: boolean,
    purchasedNFTs: any[],
    totalPrice: number
}

const initialState = {
    state: false,
    approveStatus: false,
    claimStatus: false,
    purchasedNFTs: [],
    totalPrice:0
} as prizeCartState

const prizeCartSlice = createSlice(
    {
        name: 'prize cart',
        initialState,
        reducers: {
            setPrizeCartStatus(state, action) {
                state.state = action.payload;
            },
            setApproveStatus(state, action) {
                state.approveStatus = action.payload;
            },
            setClaimStatus(state, action) {
                state.claimStatus = action.payload;
            },
            setPurchasedNFTs(state, action){
                state.purchasedNFTs = action.payload;
            },
            setTotalPrice(state, action) {
                state.totalPrice = action.payload;
            }
        }
    }
)

export const {
    setPrizeCartStatus,
    setApproveStatus,
    setClaimStatus,
    setPurchasedNFTs,
    setTotalPrice
} = prizeCartSlice.actions;

export default prizeCartSlice.reducer