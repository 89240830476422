import React from 'react';
import db from './firestore.config';
import {setDoc, doc, deleteDoc, updateDoc, getDoc, getDocs, addDoc, deleteField} from "firebase/firestore";
import {useAppDispatch, useAppSelector} from "../hooks";
import {setLoadingStatus, setWidgetCartStatus, setWidgetItems, setWidgetStats} from "../redux/reducer/widget";
import {show} from "../redux/reducer/notification";
import {useWeb3Functions} from "./useWeb3Functions";
import {useFetchHolders} from "../Web3/nft-owner";
import {useEliminateMange} from "./EliminateMange";

export const useCartFunctions = () => {
    const dispatch = useAppDispatch();
    const mintData = useAppSelector(state => state.wallet.getMintData);
    const address = useAppSelector(state => state.wallet.walletAddress);
    const supplyByUser = useAppSelector(state => state.wallet.supplyByUser);

    // const cartFunctions = useCartFunctions();
    const web3Functions = useWeb3Functions();
    // const fetchHolders = useFetchHolders();
    // const eliminateManage = useEliminateMange();


    const walletValidation = () => {
        if (address === '') {
            dispatch(show({title: "Wallet disconnected", body: '', type: "error"}))
            return;
        }
        if (address === 'wrong') {
            dispatch(show({title: "Wrong Network", body: '', type: "error"}))
            return;
        }
    }
    /// Writable functions are here == = = = = ===== = =
    const useAddToCart = async (address: string, id: number, amount: number) => {

        walletValidation();
        const cartRef = doc(db, 'cart', address);
        const cartSnap = await getDoc(cartRef);

        try {
            if (cartSnap.exists()) {
                await (updateDoc(cartRef, {[id]: amount}));
                const cartNewSnap = await getDoc(cartRef);
                dispatch(setWidgetItems(cartNewSnap.data()));
                dispatch(show({title: "Added to cart", body: '', type: "success"}))
            } else {
                await setDoc(cartRef, {[id]: amount});
                const cartNewSnap = await getDoc(cartRef);
                dispatch(setWidgetItems(cartNewSnap.data()));
                dispatch(show({title: "Added to cart", body: '', type: "success"}))
            }
        } catch (error) {
            console.error(error);
            dispatch(show({title: "Added to cart failed", body: '', type: "error"}))
        }
    }

    const useClearAll = async (address: string) => {

        walletValidation();
        try {
            await deleteDoc(doc(db, 'cart', address));
            dispatch(setWidgetItems({}));
            dispatch(show({title: "Cart cleared", body: '', type: "success"}))
        } catch (error) {
            console.error(error);
        }
    }

    const useRemoveItem = async (address: string, id: number) => {
        walletValidation();
        const cartRef = doc(db, 'cart', address);
        const cartSnap = await getDoc(cartRef);
        dispatch(setWidgetCartStatus(false));
        try {
            await (updateDoc(cartRef, {[id]: deleteField()}));
            const cartNewSnap = await getDoc(cartRef);
            dispatch(setWidgetItems(cartNewSnap.data()));
            dispatch(show({title: "Removed from the cart", body: '', type: "success"}))
        } catch (error) {
            console.error(error);
            dispatch(show({title: "Removed from the cart failed", body: '', type: "error"}))
        }
    }

    const useIncreaseCartAmount = async (address: string, id: number, amount: number) => {
        walletValidation();
        const cartRef = doc(db, 'cart', address);
        const cartSnap = await getDoc(cartRef);
        try {
            if (amount < mintData[3] - supplyByUser[id]) {
                await (updateDoc(cartRef, {[id]: amount + 1}));
            }
            const cartNewSnap = await getDoc(cartRef);
            dispatch(setWidgetItems(cartNewSnap.data()));
        } catch (error) {
            console.error(error);
        }
    }
    const useDecreaseCartAmount = async (address: string, id: number, amount: number) => {
        walletValidation();
        const cartRef = doc(db, 'cart', address);
        const cartSnap = await getDoc(cartRef);
        try {
            if (amount > 1) {
                await (updateDoc(cartRef, {[id]: amount - 1}));
            }
            const cartNewSnap = await getDoc(cartRef);
            dispatch(setWidgetItems(cartNewSnap.data()));
        } catch (error) {
            console.error(error);
        }
    }


/// Readable functions are here == = = = = ===== = =

    const getItems = async () => {
        // walletValidation();

        if (address && address !== '') {
            const cartRef = doc(db, 'cart', address);
            const cartSnap = await getDoc(cartRef);
            if (cartSnap.exists()) {
                dispatch(setWidgetItems(cartSnap.data()));
            }
        } else {
            console.log('wallet disconnected')
        }
    }

    return {
        useAddToCart, useClearAll, useRemoveItem, useIncreaseCartAmount, useDecreaseCartAmount,
        ///readable functions
        getItems
    }
}
