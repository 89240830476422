import {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {setWidgetCartStatus, setWidgetStats, setTotalPrice} from "../../redux/reducer/widget";
import CartIcon from "../../assets/cart.svg";
import {flags, mappingData} from "../AllTabContent/Flags";
import trashIcon from "../../assets/trash.svg";
import maticIcon from "../../assets/currencies/Polygon.svg";
import infoIcon from "../../assets/info-circle-zinc.svg";
import {Tooltip} from "@mui/material";
import {useCartFunctions} from "../../services/CartFunctions";

import {NumericFormat} from "react-number-format";
import {MintNFT} from "./MintNFT";

export const Cart = (props: any) => {
    const dispatch = useAppDispatch();
    const refArea: any = useRef();
    const cartFunctions = useCartFunctions();
    const address = useAppSelector(state => state.wallet.walletAddress);
    const Items = useAppSelector(state => state.widget.items);
    const [nftAmounts, setNftAmounts] = useState(1);
    const mintData = useAppSelector(state => state.wallet.getMintData);
    const mintPrice = useAppSelector(state => state.wallet.getTokenPrice);
    const totalSupply = useAppSelector(state => state.wallet.totalSupply);
    const supplyByUser = useAppSelector(state => state.wallet.supplyByUser);
    const maticPrice = useAppSelector(state => state.wallet.maticPrice);
    const totalPrice = useAppSelector(state => state.widget.totalPrice);

    const cartWidgetClose = () => {
        dispatch(setWidgetStats(false));
    }

    const getPrice = (supplies: number) => {
        const initialCost = Number(mintData[2]);
        const increaseStep = Number(mintData[6]);
        const increaseUnit = Number(mintData[7]);
        return initialCost + Math.floor(supplies / increaseStep) * increaseUnit;
    }

    useEffect(() => {
        let price = 0;
        const increaseStep = mintData[6];
        const maxSupplyPerUser = mintData[3];

        if (Object.keys(Items).length > 0) {
            Object.keys(Items).forEach(key => {
                const supply = Items[key];
                const supplies = totalSupply[Number(key)];
                const additional = supplies % increaseStep;
                const stepToIncrease = increaseStep - additional;
                let amount;
                if (supply >= stepToIncrease) {
                    const countToIncrease = supply - stepToIncrease;
                    const amountIncreased = getPrice(supplies + supply) * countToIncrease;
                    const amountNotIncreased = getPrice(supplies) * (supply - countToIncrease);
                    amount = amountIncreased + amountNotIncreased;
                } else {
                    amount = getPrice(supplies) * supply;
                }
                price += amount;
            });
            dispatch(setTotalPrice(price.toFixed(2)));
        } else {
            dispatch(setTotalPrice(0))
        }

    }, [Items]);

    return (
        <>
            <div
                className="poppin-text overflow-y-auto overflow-x-hidden fixed top-0 right-0 z-50 w-full h-full flex justify-end">
                <div className="relative p-4 w-full 2xl:max-w-lg sm:max-w-md max-w-sm h-full">
                    <div
                        className="relative mobile-cart rounded-t-[30px] sm:rounded-2xl bg-[#1F1822] h-full sm:border sm:border-2 border-blue-500 p-4"
                        ref={refArea}>
                        <div
                            className="flex justify-between items-center p-4 pt-0 rounded-t border-b border-zinc-600 relative">
                            <p className="flex flex-row text-base font-semibold gap-x-2 text-white items-center">
                                <img src={CartIcon} className={'w-5'}/> Minting Cart
                                <Tooltip title={'Here is Cart'} arrow={true} placement={'bottom'}>
                                    <img src={infoIcon} alt={'info-icon'} className={'w-4'}/>
                                </Tooltip>
                            </p>
                            <button type="button" onClick={() => {
                                cartWidgetClose()
                            }}
                                    className="text-zinc-400 font-bold bg-transparent hover:text-zinc-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center absolute right-0"
                                    data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                          clipRule="evenodd"></path>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="flex flex-col py-5">
                            <div className={'flex flex-row justify-between w-full mb-2'}>
                                <span
                                    className={`text-white text-sm font-semibold`}>{Object.keys(Items).length ? Object.keys(Items).length : 0} Item(s)</span>
                                <button onClick={() => {
                                    cartFunctions.useClearAll(address);
                                }} className={`text-white text-sm font-semibold hover:text-blue-500`}>Clear all
                                </button>
                            </div>
                            <div
                                className={'flex flex-col gap-y-3 py-5 w-full overflow-y-auto max-h-[40vh] sm:max-h-[65vh]'}>
                                {Object.keys(Items).length > 0 ? Object.keys(Items).map((key: any, index: any) => (
                                    <div className={'flex flex-row items-center justify-between'} key={index + 1}>
                                        <div className={`flex flex-row items-center gap-x-2`}>
                                            <Tooltip title={'Remove from cart'} arrow={true} placement={'bottom'}>
                                                <button onClick={() => {
                                                    cartFunctions.useRemoveItem(address, key);
                                                }}>
                                                    <img src={trashIcon} className={'w-6 trash-icon'}
                                                         alt={'trash-icon'}/>
                                                </button>
                                            </Tooltip>
                                            <div className={'flex flex-row gap-x-1 items-center text-white'}>
                                                <img src={flags[mappingData[key]]} alt={'flag'}
                                                     className={'w-8 rounded'}/>
                                                <div className={'flex flex-col'}>
                                                    <span className={'text-white text-xs'}>{mappingData[key]}</span>
                                                    <span
                                                        className={'text-zinc-400 text-[10px]'}>Available: {mintData[3] - supplyByUser[key] > 0 ? mintData[3] - supplyByUser[key] : 0}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'flex flex-row items-center justify-center gap-x-3'}>
                                            <div className={`nft-amounts w-20 h-9 flex justify-between flex-row`}>
                                                <button className={`w-1/3 text-zinc-400`}
                                                        onClick={() => {
                                                            cartFunctions.useDecreaseCartAmount(address, key, Items[key])
                                                        }}>-
                                                </button>
                                                <input
                                                    type={"text"}
                                                    placeholder="0.0"
                                                    value={Items[key] > 0 ? Items[key] : 1} onChange={(e: any) => {
                                                    setNftAmounts(e.target.value);
                                                }}
                                                    className="bg-transparent input w-1/3 outline-none border-none shadow-none text-white p-0 text-center text-base font-bold py-1"
                                                    id="nft-input"
                                                />
                                                <button className={`w-1/3 text-zinc-400`}
                                                        onClick={() => {
                                                            cartFunctions.useIncreaseCartAmount(address, key, Items[key])
                                                        }}>+
                                                </button>
                                            </div>
                                            <div
                                                className={`text-white font-semibold flex items-center gap-x-1 justify-center`}>
                                                <img src={maticIcon} alt="matic_icon" className={`w-6`}/>
                                                <div className={'flex flex-col'}>
                                                    <span className={'text-[10px] leading-[12px] text-zinc-500'}>MATIC</span>
                                                    <span
                                                        className={'text-[15px] leading-[16px] text-white font-semibold'}>{mintPrice[key] > 0 ? mintPrice[key] : mintData[2]}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) : (
                                    <div
                                        className={'flex flex-row items-center text-center text-zinc-400 justify-center'}>
                                        There is no item.
                                    </div>
                                )}
                            </div>
                            <div className={'py-5 flex flex-row justify-between border-t border-zinc-600 items-center'}>
                                <div className={'flex text-white text-lg font-semibold'}>
                                    Total Price
                                </div>
                                <div className={`text-white font-semibold flex items-center gap-x-1 justify-center`}>
                                    <img src={maticIcon} alt="matic_icon" className={`w-8`}/>
                                    <div className={'flex flex-col'}>
                                        <span className={'text-[10px] leading-[12px] text-zinc-500'}>MATIC</span>
                                        <span
                                            className={'text-[15px] leading-[16px] text-white font-semibold'}><NumericFormat
                                            value={totalPrice ? totalPrice : 0} decimalScale={2} displayType={'text'}
                                            fixedDecimalScale={true} thousandSeparator={true}/></span>
                                        <span
                                            className={'text-[10px] leading-[11px] text-zinc-500 font-semibold'}>
                                            <NumericFormat
                                                value={totalPrice ? totalPrice * maticPrice : 0} decimalScale={2}
                                                displayType={'text'}
                                                fixedDecimalScale={true} thousandSeparator={true} prefix={'~$'}/></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute bottom-0 left-0 right-0 w-full  flex items-center p-6 space-x-2">
                            <MintNFT/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}