import {Alchemy, Network} from "alchemy-sdk";
import {useAppDispatch, useAppSelector} from "../hooks";
import {setHolders, setOwnedNFTs} from "../redux/reducer/wallet";
import {alchemy_id, mint_addr} from "../services/link.config";

export const useFetchHolders = () => {
    const dispatch = useAppDispatch();
    const walletAddress = useAppSelector(state => state.wallet.walletAddress);
    // Mint contract address
    const address = mint_addr;
    const config = {
        // apiKey: alchemy_id,
        apiKey: '_LJc7DjEfM4j_-LgLkBWZUf64FNfS_hV',//'_LJc7DjEfM4j_-LgLkBWZUf64FNfS_hV',
        network: Network.MATIC_MAINNET//MATIC_MAINNET
        // apiKey: 'Yf_fSDXqzEOoJBDZkIJ5axEr58rwfFCa',
        // network: Network.MATIC_MAINNET//MATIC_MAINNET
    }

    const alchemy = new Alchemy(config);

    const main = async () => {
        // const address = '0xFA73C01E9DF6BeBCBA5487dCA4c22B870688Fa20';
        // console.log("address", address);
        // Get owners
        if (address) {
            try {
                const owners = await alchemy.nft.getOwnersForContract(address);
                dispatch(setHolders(owners.owners.length));
                const amounts = await alchemy.nft.getNftsForOwner(walletAddress);
                dispatch(setOwnedNFTs(amounts.ownedNfts));
            } catch (e) {
                // console.log("owner-issue", e);
            }
        }
    };

    const runFetch = async () => {
        try {
            await main();
        } catch (error) {
            console.log(error);
        }
    };

    return {runFetch}
}
