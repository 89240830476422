import React, {useState} from 'react';
import {ethers} from "ethers";
import usdc_abi from "../utils/USDCabi.json";
import mint_abi from "../utils/MintAbi.json";
import price_abi from "../utils/PriceAbi.json";
import distribution_abi from "../utils/DistributionAbi.json";
import {useAppDispatch, useAppSelector} from "../hooks";
import {
    setApproved,
    setGoerliBalance,
    setLoading, setMaticPrice,
    setMintData, setRewardData,
    setSupplyByUser,
    setTokenPrice,
    setTotalSupply,
    setUsdcBalance
} from "../redux/reducer/wallet";

import {BigNumber} from "bignumber.js";
import {show} from "../redux/reducer/notification";
import {utils} from "ethers/lib.esm";
import {deleteDoc, doc} from "firebase/firestore";
import db from "./firestore.config";
import {setLoadingStatus, setWidgetItems, setWidgetStats} from "../redux/reducer/widget";
import {alchemy_id, alchemy_rpc, mint_addr, distribution_addr, usdc_addr} from "./link.config";
import {setApproveStatus, setClaimStatus, setPrizeCartStatus} from "../redux/reducer/prizeCart";

let USDCPortal: any;
let mintPortal: any;
let priceFeed: any;
let distributionPortal: any;

export const useWeb3Functions = () => {
    const {ethereum} = (window as any);
    const dispatch = useAppDispatch();
    const address = useAppSelector(state => state.wallet.walletAddress);
    const balance = useAppSelector(state => state.wallet.walletAmount);
    const Items = useAppSelector(state => state.widget.items);
    const totalPrice = useAppSelector(state => state.widget.totalPrice);

    //get USDC balance =====
    const USDCBalance = async (address: string) => {
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            USDCPortal = new ethers.Contract(usdc_addr, usdc_abi, signer);
            let balanceUSDC_ = await USDCPortal.balanceOf(address);
            let balanceUSDC = new BigNumber(Number(balanceUSDC_)).dividedBy(10 ** 6).toFixed(2);
            dispatch(setUsdcBalance(balanceUSDC));
            return balanceUSDC;
        }
    }

    const GoerliBalance = async () => {
        if (address !== '') {
            try {
                const provider = ethers.getDefaultProvider(alchemy_rpc);
                let balanceETH_ = await provider.getBalance(address);
                let balanceETH = new BigNumber(Number(balanceETH_)).dividedBy(10 ** 18).toFixed(3);
                dispatch(setGoerliBalance(Number(balanceETH)));
            } catch (e) {
                console.log(e);
            }
        }
    }

    const mintNFT = async (id: number, supply: number) => {
        if (ethereum) {
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            mintPortal = new ethers.Contract(mint_addr, mint_abi, signer);
            try {
                console.log(id, supply)
                // let mint = await mintPortal.mint(id, supply);
                // const mintResult = await mint.wait();
            } catch (e: any) {
                dispatch(show({title: "Transaction Failed", body: '', type: 'error'}));
            }
        }
    }

    const mintBatchNFT = async () => {
        if (ethereum) {

            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            mintPortal = new ethers.Contract(mint_addr, mint_abi, signer);
            let ids: any = [];
            let amounts: any = [];

            if (Object.keys(Items).length < 1) {
                dispatch(show({title: "There is no item", body: '', type: 'error'}));
                return;
            }
            dispatch(setLoading(true));
            if (totalPrice >= balance) {
                dispatch(show({title: "Insufficient Balance", body: '', type: 'error'}));
                dispatch(setLoading(false));
            } else {
                try {
                    console.log("address", address)
                    Object.keys(Items).forEach(key => {
                        ids.push(key);
                        amounts.push(Items[key]);
                    })
                    let overrides = {
                        from: address,
                        value: utils.parseEther(totalPrice.toString()),
                    }
                    let mintBatch = await mintPortal.batchMint(ids, amounts, overrides);
                    const mintBatchResult = await mintBatch.wait();
                    await deleteDoc(doc(db, 'cart', address));
                    dispatch(setLoadingStatus(true));
                    dispatch(setWidgetItems({}));
                    dispatch(setWidgetStats(false));
                    dispatch(show({title: "Transaction Completed", body: '', type: 'success'}));
                    dispatch(setLoading(false));
                } catch (e: any) {
                    console.log("batchMint error", e);
                    dispatch(show({title: "Transaction Failed", body: '', type: 'error'}));
                    dispatch(setLoading(false));
                }
            }
        }
    }

    /////// prize distribution functional parts =======
    const approveNFTs = async () => {
        if (ethereum) {
            dispatch(setApproveStatus(true));
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            mintPortal = new ethers.Contract(mint_addr, mint_abi, signer);
            try {
                let approve = await mintPortal.setApprovalForAll(distribution_addr, true);
                approve = await approve.wait();
                console.log("approve result", approve);
                dispatch(setApproveStatus(false));
                window.localStorage.setItem('approve_status', 'approved');
                dispatch(show({title: "Approve Succeed", body: '', type: 'success'}));
                dispatch(setPrizeCartStatus(true));
            } catch (e) {
                dispatch(setApproveStatus(false));
                dispatch(show({title: "Approve Failed", body: '', type: 'error'}));
                console.log("approve error", e);
            }
        }
    }

    const claimReward = async () => {
        if (ethereum) {
            dispatch(setClaimStatus(true));
            const provider = new ethers.providers.Web3Provider(ethereum);
            const signer = provider.getSigner();
            distributionPortal = new ethers.Contract(distribution_addr, distribution_abi, signer);
            try {
                let claim = await distributionPortal.distribution();
                claim = await claim.wait();
                console.log("claim result", claim);
                dispatch(setClaimStatus(false));
                dispatch(setPrizeCartStatus(false));
                dispatch(show({title: "Claim Reward Success!", body: '', type: 'success'}));
            } catch (e) {
                dispatch(setClaimStatus(false));
                dispatch(show({title: "Claim Reward Error!", body: '', type: 'error'}));
                console.log("claim error", e);
            }
        }
    }

    const getTokenPrice = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(alchemy_rpc);
            mintPortal = new ethers.Contract(mint_addr, mint_abi, provider);
            const promises = [];
            for (let i = 0; i < 32; i++) {
                promises.push(mintPortal.getTokenPrice(i));
            }

            let temp: any = [];
            let responses = await Promise.all(promises)
            responses.forEach((response, index) => {
                temp.push(new BigNumber(Number(response)).dividedBy(10 ** 18).toFixed(2));
            })
            dispatch(setTokenPrice(temp));
        } catch (e) {
            console.log(e)
        }
    }

    const getTotalSupply = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(alchemy_rpc);
            mintPortal = new ethers.Contract(mint_addr, mint_abi, provider);
            const promises = [];
            for (let i = 0; i < 32; i++) {
                promises.push(mintPortal.totalSupply(i));
            }
            let temp: any = [];
            let responses = await Promise.all(promises);
            responses.forEach((response, index) => {
                temp.push(Number(response));
            });
            dispatch(setTotalSupply(temp));
        } catch (e) {

        }

    }

    const getSupplyByUser = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(alchemy_rpc);
            mintPortal = new ethers.Contract(mint_addr, mint_abi, provider);
            const promises = [];
            for (let i = 0; i < 32; i++) {
                if (address != '') {
                    promises.push(mintPortal.supplyByUser(address, i));
                }
            }
            let temp: any = [];
            let responses = await Promise.all(promises);
            responses.forEach((response, index) => {
                temp.push(Number(response));
            });
            dispatch(setSupplyByUser(temp));
        } catch (e) {
            console.log(e);
        }

    }

    const getMaticPrice = async () => {
        try {
            const addr = '0xAB594600376Ec9fD91F8e885dADF0CE036862dE0'; //chainlink node contract address
            const provider = new ethers.providers.JsonRpcProvider(alchemy_rpc);
            priceFeed = new ethers.Contract(addr, price_abi, provider);

            const roundData = await priceFeed.latestRoundData();
            let decimals = await priceFeed.decimals();

            const thePrice = Number((roundData.answer.toString() / Math.pow(10, decimals)).toFixed(4));
            dispatch(setMaticPrice(thePrice));
        } catch (e) {
            console.log(e);
        }
    }

    const getMintData = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(alchemy_rpc);
            mintPortal = new ethers.Contract(mint_addr, mint_abi, provider);
            if (address && address !== '') {
                dispatch(setApproved(await mintPortal.isApprovedForAll(address, distribution_addr)));
            }
            const promises = [];
            promises.push(mintPortal.totalMinted());
            promises.push(mintPortal.totalPool());
            promises.push(mintPortal.getInitialCost());
            promises.push(mintPortal.getMaxSupplyPerUser());
            promises.push(mintPortal.getMaxSupplyPerToken());
            promises.push(mintPortal.endTime());
            promises.push(mintPortal.getIncreaseStep());
            promises.push(mintPortal.getIncreaseUnit());
            // promises.push(10000000000000000000);// initial cost
            // promises.push(10);// supply per user
            // promises.push(2000);//supply per token
            // promises.push(1671372000);//  end time
            // promises.push(100);// increase stepincrease unit
            // promises.push(1000000000000000000); // increase unit
            let temp: any = [];
            let responses = await Promise.all(promises);
            responses.forEach((response, index) => {
                temp.push(response);
            })
            temp[0] = Number(temp[0]);
            temp[1] = new BigNumber(Number(temp[1])).dividedBy(10 ** 18).toFixed(2);
            temp[2] = new BigNumber(Number(temp[2])).dividedBy(10 ** 18).toFixed(2);
            temp[3] = Number(temp[3]);
            temp[4] = Number(temp[4]);
            temp[5] = Number(temp[5]);
            temp[6] = Number(temp[6]);
            temp[7] = new BigNumber(Number(temp[7])).dividedBy(10 ** 18).toFixed(2);
            dispatch(setMintData(temp));
        } catch (e) {
            console.log("error", e)
        }
    }

    const getRewardData = async () => {
        try {
            const provider = new ethers.providers.JsonRpcProvider(alchemy_rpc);
            distributionPortal = new ethers.Contract(distribution_addr, distribution_abi, provider);
            const promises = [];

            promises.push(distributionPortal.startTimestamp());
            promises.push(distributionPortal.remainingPool());
            let temp: any = [];
            let responses = await Promise.all(promises);
            responses.forEach((response, index) => {
                temp.push(response);
            });

            temp[0] = Number(temp[0]);
            temp[1] = new BigNumber(Number(temp[1])).dividedBy(10 ** 18).toFixed(2);
            dispatch(setRewardData(temp));
        } catch (e) {
            console.log(e);
        }
    }

    return {
        USDCBalance,
        mintNFT,
        mintBatchNFT,
        getMintData,
        getTokenPrice,
        getTotalSupply,
        getSupplyByUser,
        getMaticPrice,
        GoerliBalance,
        getRewardData,

        //distribution part
        approveNFTs,
        claimReward
    }
}