import "./MainTabs.css";
import {NumericFormat} from 'react-number-format';
import infoIcon from "../../assets/info-circle-zinc.svg";
import {ToggleButton, Tooltip} from "@mui/material";
import {Timer} from "./Timer";
import {useAppSelector} from "../../hooks";
import {useEffect, useState} from "react";

export const MainTabs = (props: any) => {
    const mintData = useAppSelector(state => state.wallet.getMintData);
    const rewardData = useAppSelector(state => state.wallet.getRewardData);
    const holders = useAppSelector(state => state.wallet.holders);
    const groupStage = useAppSelector(state => state.widget.groupStage);
    const [currentTime, setCurrentTime] = useState(new Date().getTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().getTime())
        }, 1000)

        return () => clearInterval(interval)
    }, [])
    const seconds: any = Math.floor((mintData[5] * 1000 - currentTime) / 1000);
    const rewardSeconds: any = Math.floor((rewardData[0] * 1000 - currentTime) / 1000);

    return (
        <>
            <div className={`flex flex-col`}>
                <div className={`flex flex-row gap-x-12 2xl:gap-x-20 block sm:hidden justify-center mb-6`}>
                    <div className={`flex flex-col gap-y-2 items-center`}>
                        <Tooltip title={'Minting closes on 18 December 2022 (02:00 PM UTC)'} arrow={true}
                                 placement={'top'}><span className={`text-zinc-500 text-lg sm:text-base font-semibold`}>
                            {seconds > 0 ? 'Minting close' : rewardSeconds > 0 ? 'Claiming will start' : ''}
                        </span></Tooltip>
                        {(seconds > 0 || rewardSeconds > 0) && <Timer/>}
                    </div>
                </div>
                <div className="flex items-center w-full gap-x-4 sm:justify-between justify-center">
                    <div className={`flex flex-row gap-x-12 2xl:gap-x-20`}>
                        <div className={`flex flex-row gap-x-2 items-center`}>
                            <Tooltip title={'Total number of minted NFTs on the platform'} arrow={true}
                                     placement={'top'}>
                                <span className={`text-zinc-500 text-sm sm:text-base font-semibold`}>
                                Total Minted
                            </span>
                            </Tooltip>
                            <span className={`text-white text-base sm:text-2xl font-bold`}>
                                <NumericFormat value={mintData[0]} displayType={'text'}
                                               thousandSeparator={true}/>
                            </span>
                        </div>
                        <div className={`flex flex-row gap-x-2 items-center`}>
                            <Tooltip title={'Total number of unique NFT holders'} arrow={true}
                                     placement={'top'}>
                                <span className={`text-zinc-500 text-sm sm:text-base font-semibold`}>
                                    Total Holders
                                </span>
                            </Tooltip>
                            <span className={`text-white text-base sm:text-2xl font-bold`}>
                                <NumericFormat value={holders} displayType={'text'} thousandSeparator={true}/>
                            </span>
                        </div>
                    </div>

                    <div className={`flex flex-row gap-x-12 2xl:gap-x-20 hidden sm:block`}>
                        <div className={`flex flex-row gap-x-2 items-center`}>
                            <Tooltip title={'Minting closes on 18 December 2022 (02:00 PM UTC)'} arrow={true}
                                     placement={'top'}>
                                <span className={`text-zinc-500 text-base font-semibold`}>
                                {seconds > 0 ? 'Minting close' : rewardSeconds > 0 ? 'Claiming will start' : ''}
                                </span>
                            </Tooltip>
                            {(seconds > 0 || rewardSeconds > 0) && <Timer/>}
                        </div>
                    </div>
                </div>
                <div className={`flex flex-row grid grid-cols-4 mt-5 hidden sm:grid`}>
                    <div
                        className={'items-center flex flex-row justify-center text-center text-sm text-zinc-500 gap-x-1'}>Team
                        <Tooltip title={'All teams that participate in World Cup Qatar 2022'} arrow={true}
                                 placement={'bottom'}>
                            <img src={infoIcon} alt={'info-icon'} className={'w-4'}/>
                        </Tooltip>
                    </div>
                    <div
                        className={'items-center flex flex-row justify-center text-center text-sm text-zinc-500 gap-x-1'}>Supply
                        <Tooltip
                            title={'Total number of minted NFTs for each team on the platform. Players can track the progress here'}
                            arrow={true} placement={'bottom'}>
                            <img src={infoIcon} alt={'info-icon'} className={'w-4'}/>
                        </Tooltip>
                    </div>
                    {Object.keys(groupStage).length < 32 ? (
                        <div
                            className={'items-center flex flex-row justify-start  gap-x-3 2xl:gap-x-8 text-center text-zinc-500'}>
                            <Tooltip
                                title={'The amount of prize in MATIC per NFT for 1st place'}
                                arrow={true} placement={'bottom'}><span
                                className={'text-zinc-500 text-sm w-20'}>1st Place</span></Tooltip>
                            <Tooltip
                                title={'The amount of prize in MATIC per NFT for 2nd place'}
                                arrow={true} placement={'bottom'}><span
                                className={'text-zinc-500 text-sm w-20'}>2nd Place</span></Tooltip>
                            <Tooltip
                                title={'The amount of prize in MATIC per NFT for 3rd place'}
                                arrow={true} placement={'bottom'}><span
                                className={'text-zinc-500 text-sm w-20'}>3rd Place</span></Tooltip>
                        </div>
                    ) : (
                        <div
                            className={'items-center flex flex-row justify-start  gap-x-3 2xl:gap-x-8 text-center text-zinc-500'}>
                            <Tooltip title={'The amount of prize in MATIC per NFT'}
                                     arrow={true} placement={'bottom'}><span
                                className={'text-zinc-500 text-sm w-full'}>Prize Amount</span></Tooltip>
                        </div>
                    )}

                    <div
                        className={'items-center flex flex-row text-left 2xl:text-center text-sm text-zinc-500  grid grid-cols-7'}>
                        <Tooltip title={'The current mint price for 1 NFT (in MATIC)'} arrow={true}
                                 placement={'bottom'}>
                            <div className={`flex flex-row gap-x-1 justify-end col-span-2 2xl:col-span-3`}>Mint Price
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </>

    );
};
