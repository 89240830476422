import React, {useEffect, useState} from "react";
import "./ContentRow.css";
import maticIcon from "../../../assets/currencies/Polygon.svg";
import ticketIcon from "../../../assets/ticket.svg";
import giftIcon from "../../../assets/giftIcon.svg";
import ProgressBar from "@ramonak/react-progress-bar";
import {flags, flagUrls} from "../Flags";
import flashIcon from "../../../assets/flash.svg";
import plusIcon from "../../../assets/Plus.svg";
import minusIcon from "../../../assets/Minus.svg";
import CartIcon from "../../../assets/cart.svg";
import {useCartFunctions} from "../../../services/CartFunctions";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {setLoadingStatus, setWidgetStats} from "../../../redux/reducer/widget";
import {useWeb3Functions} from "../../../services/useWeb3Functions";
import {styled, Tooltip, tooltipClasses, TooltipProps, Typography} from "@mui/material";
import {NumericFormat} from "react-number-format";
import {mint_addr, usdc_addr} from "../../../services/link.config";
import trophyIcon from "../../../assets/trophy-1.svg";
import {show} from "../../../redux/reducer/notification";

export const ContentRow = (props: any) => {
        const dispatch = useAppDispatch();
        const address = useAppSelector(state => state.wallet.walletAddress);
        const Items = useAppSelector(state => state.widget.items);
        const cartFunctions = useCartFunctions();
        const web3Functions = useWeb3Functions();
        const mintData = useAppSelector(state => state.wallet.getMintData);
        const mintPrice = useAppSelector(state => state.wallet.getTokenPrice);
        const totalSupply = useAppSelector(state => state.wallet.totalSupply);
        const supplyByUser = useAppSelector(state => state.wallet.supplyByUser);
        const ownedNFTs = useAppSelector(state => state.wallet.ownedNFTs);

        //eliminated group;
        const groupStage = useAppSelector(state => state.widget.groupStage);

        if (address && address !== '') {
            dispatch(setLoadingStatus((totalSupply.length < 1 || supplyByUser.length < 1 || mintData.length < 1)));
        } else {
            dispatch(setLoadingStatus((totalSupply.length < 1 || mintData.length < 1)));
        }
        const mintLoadingStatus = useAppSelector(state => state.widget.loadingStatus);

        let firstPlacePrize;
        let secondPlacePrize;
        let thirdPlacePrize;
        let fourthPlacePrize;
        let qfPlacePrize;
        let r16PlacePrize;
        let grpPlacePrize;
        if (props.data.supply > 0) {
            firstPlacePrize = mintData[1] * 0.5 / props.data.supply;
            secondPlacePrize = mintData[1] * 0.175 / props.data.supply;
            thirdPlacePrize = mintData[1] * 0.1 / props.data.supply;
            fourthPlacePrize = mintData[1] * 0.05 / props.data.supply;
            qfPlacePrize = mintData[1] * 0.04 / (props.data.supply * 4);
            r16PlacePrize = mintData[1] * 0.025 / (props.data.supply * 8);
            grpPlacePrize = mintData[1] * 0.01 / (props.data.supply * 16);
        } else {
            firstPlacePrize = mintData[1] * 0.5;
            secondPlacePrize = mintData[1] * 0.175;
            thirdPlacePrize = mintData[1] * 0.1;
            fourthPlacePrize = mintData[1] * 0.05;
            qfPlacePrize = mintData[1] * 0.04 / 4;
            r16PlacePrize = mintData[1] * 0.025 / 8;
            grpPlacePrize = mintData[1] * 0.01 / 16;
        }

        const prizeList = [firstPlacePrize, secondPlacePrize, thirdPlacePrize, fourthPlacePrize, qfPlacePrize, r16PlacePrize, grpPlacePrize];
        const prizeText = ['1st Place Prize', '2nd Place Prize', '3rd Place Prize', '4th Place Prize', 'Quarter Final Prize', 'Round 16 Prize', 'Group Stage Prize'];

        const getPrice = (supplies: number) => {
            const initialCost = Number(mintData[2]);
            const increaseStep = Number(mintData[6]);
            const increaseUnit = Number(mintData[7]);
            return initialCost + Math.floor(supplies / increaseStep) * increaseUnit;
        }
        const trophyElement = <img src={trophyIcon} className={'w-8'} alt={'trophy-icon'}/>
        const getNftAmounts = (ownedNFTs: any) => {
            let amounts = 0;
            if (ownedNFTs.length > 0) {
                for (let nft of ownedNFTs) {
                    if (nft.contract.address.toLowerCase() == mint_addr.toLowerCase() && nft.tokenId == props.data.countryId) {
                        amounts = nft.balance;
                    }
                }
                return amounts;
            } else {
                return 0;
            }
        }

        const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
            <Tooltip {...props} classes={{popper: className}}/>
        ))(({theme}) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: '#332C35',
                color: '#ffffff',
                maxWidth: 240,
                width: 240,
                borderRadius: 15,
                fontSize: theme.typography.pxToRem(12),
            },
        }));

        return (mintLoadingStatus ?
                <div
                    className={`poppin-text row border  border-zinc-700 rounded-xl px-8 py-4 flex items-center justify-between flex flex-row gap-y-5 grid gap-4`}
                >
                    <div
                        className={`flex items-center gap-x-5 2xl:gap-x-8 col-span-3 h-8 bg-zinc-800 animate-pulse rounded`}></div>
                </div>
                :
                <div
                    className={`poppin-text border ${groupStage[props.data.countryId] === 0 ? 'border-[#007AFF] bg-[#0B213F]' : 'border-zinc-700 bg-[#1f1822]'} row rounded-xl px-8 py-4 flex items-center justify-between flex flex-row gap-y-5 grid grid-cols-12 gap-4`}
                >
                    <div className={`flex items-center gap-x-5 2xl:gap-x-8 col-span-3`}>
                        <div className={`flex flex-row items-center gap-x-4 2xl:gap-x-6`}>
                    <span
                        className={`rounded-md font-semibold text-white text-center text-xl w-8`}>{groupStage[props.data.countryId] === 0 ? trophyElement : props.Number}</span>
                            <HtmlTooltip placement={'right-end'} title={
                                <p className={'p-2'}><span
                                    className={'poppin-text flex flex-row justify-center items-center text-center w-full mb-1 text-xl'}>{props.data.country}</span>
                                    <img src={flagUrls[props.data.countryId]} alt={'flag_thumbnail'}
                                         className={'rounded-[10px]'}/></p>
                            }>
                                <img className={`w-12 rounded-[5px]`} src={flags[props.data.country]} alt="country-flag"/>
                            </HtmlTooltip>
                        </div>
                        <div className={`flex items-center`}>
                            <div className={`flex flex-row gap-y-1 items-center text-left gap-x-2`}>
                                <p className={`text-white text-xl whitespace-nowrap`}>{props.data.country}</p>
                                {(address && address !== '') &&
                                <p className={`flex flex-row text-[#FAFF00] text-xl ${getNftAmounts(ownedNFTs) > 0 ? '' : 'hidden'}`}>
                                    <img src={ticketIcon} alt={'ticket-icon'}
                                         className={'w-6'}/> x {getNftAmounts(ownedNFTs)}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <Tooltip
                        title={props.data.supply > -1 ? props.data.supply + '/' + mintData[4] : 'loading...'}
                        arrow={true} placement={'bottom'}>
                        <div className={'flex flex-row items-center w-full justify-center col-span-3'}>
                            <ProgressBar
                                completed={`${props.data.supply}`}
                                customLabel={`${props.data.supply > 500 ? props.data.supply + '/' + mintData[4] : 'loading...'}`}
                                isLabelVisible={props.data.supply > 500}
                                maxCompleted={mintData[4] ? mintData[4] : 2000} className={'w-10/12 2xl:w-full'}
                                height={'24px'}
                                labelSize={'10px'} borderRadius="5px" bgColor={'#007AFF'} baseBgColor={'#192C4E'}/>
                        </div>
                    </Tooltip>
                    {groupStage[props.data.countryId] > -1 ? (
                        <div
                            className={`flex items-center gap-x-3 2xl:gap-x-8 justify-start 2xl:justify-center col-span-3`}>
                        <span
                            className={`${groupStage[props.data.countryId] === 0 ? 'text-[#FECE00]' : 'text-[#868686]'} 2xl:text-xl text-base poppin-text font-semibold w-[59%]`}>{prizeText[groupStage[props.data.countryId]]}</span>
                            <div className={`text-white font-semibold flex gap-x-1 justify-start text-left w-[30%]`}>
                                <img src={maticIcon} alt="matic_icon" className={`w-7`}/>
                                <div className={'flex flex-col'}>
                                    <span className={'text-[10px] text-zinc-500 leading-[12px]'}>MATIC</span>
                                    <span
                                        className={'text-[19px] text-white font-semibold leading-[20px]'}><NumericFormat
                                        value={prizeList[groupStage[props.data.countryId]] > -1 ? (prizeList[groupStage[props.data.countryId]]).toFixed(2) : 1.00}
                                        decimalScale={2}
                                        fixedDecimalScale={true} displayType={'text'} thousandSeparator={true}/></span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className={`flex items-center gap-x-3 2xl:gap-x-8 justify-start 2xl:justify-center col-span-3`}>
                            <div className={`text-white font-semibold flex items-center gap-x-1 justify-center w-[30%]`}>
                                <img src={maticIcon} alt="matic_icon" className={`w-7`}/>
                                <div className={'flex flex-col'}>
                                    <span className={'text-[10px] text-zinc-500 leading-[12px]'}>MATIC</span>
                                    <span
                                        className={'text-[19px] text-white font-semibold leading-[20px]'}><NumericFormat
                                        value={firstPlacePrize ? (firstPlacePrize).toFixed(2) : 1.00} decimalScale={2}
                                        fixedDecimalScale={true} displayType={'text'} thousandSeparator={true}/></span>
                                </div>
                            </div>
                            <div className={`text-white font-semibold flex items-center gap-x-1 justify-center w-[30%]`}>
                                <img src={maticIcon} alt="matic_icon" className={`w-7`}/>
                                <div className={'flex flex-col'}>
                                    <span className={'text-[10px] text-zinc-500 leading-[12px]'}>MATIC</span>
                                    <span className={'text-[19px] text-white font-semibold leading-[20px]'}><NumericFormat
                                        value={secondPlacePrize ? (secondPlacePrize).toFixed(2) : 1.00} decimalScale={2}
                                        fixedDecimalScale={true} displayType={'text'} thousandSeparator={true}/></span>
                                </div>
                            </div>
                            <div className={`text-white font-semibold flex items-center gap-x-1 justify-center w-[30%]`}>
                                <img src={maticIcon} alt="matic_icon" className={`w-7`}/>
                                <div className={'flex flex-col'}>
                                    <span className={'text-[10px] text-zinc-500 leading-[12px]'}>MATIC</span>
                                    <span className={'text-[19px] text-white font-semibold leading-[20px]'}><NumericFormat
                                        value={thirdPlacePrize ? (thirdPlacePrize).toFixed(2) : 1.00} decimalScale={2}
                                        fixedDecimalScale={true} displayType={'text'} thousandSeparator={true}/></span>
                                </div>
                            </div>
                            <div className={`text-white font-semibold flex items-center gap-x-3 justify-center`}>
                                {/*<div className={'relative'}>*/}
                                {/*    <img src={giftIcon} alt="matic_icon" className={`w-6`}/>*/}
                                {/*    <div className={'absolute'}>*/}
                                {/**/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <HtmlTooltip arrow={true} placement={'top'} title={
                                    <div className={'flex flex-col items-center p-3 gap-y-2 w-full poppin-text'}>
                                        <div className={'text-blue-500 text-base'}>Other Prize per NFT</div>
                                        <div className={'flex flex-row justify-between w-full'}>
                                            <span className={'text-[#868686] text-sm'}>4th place</span>
                                            <span
                                                className={'text-sm'}>{fourthPlacePrize ? (fourthPlacePrize).toFixed(2) + ' MATIC' : 1.00}</span>
                                        </div>
                                        <div className={'flex flex-row justify-between w-full'}>
                                            <span className={'text-[#868686] text-sm'}>Quarter Final</span>
                                            <span
                                                className={'text-sm'}>{qfPlacePrize ? (qfPlacePrize).toFixed(2) + ' MATIC' : 1.00}</span>
                                        </div>
                                        <div className={'flex flex-row justify-between w-full'}>
                                            <span className={'text-[#868686] text-sm'}>Round 16</span>
                                            <span
                                                className={'text-sm'}>{r16PlacePrize ? (r16PlacePrize).toFixed(2) + ' MATIC' : 1.00}</span>
                                        </div>
                                        <div className={'flex flex-row justify-between w-full'}>
                                            <span className={'text-[#868686] text-sm'}>Group  Stage</span>
                                            <span
                                                className={'text-sm'}>{grpPlacePrize ? (grpPlacePrize).toFixed(2) + ' MATIC' : 1.00}</span>
                                        </div>

                                    </div>
                                }>
                                    <img src={giftIcon} alt="matic_icon" className={`w-6`}/>
                                </HtmlTooltip>
                            </div>
                        </div>
                    )}

                    {groupStage[props.data.countryId] > -1 ? (
                        <div className={'flex flex-row justify-end text-right col-span-3'}>
                        <span
                            className={`poppin-text ${groupStage[props.data.countryId] === 0 ? 'text-[#FECE00]' : 'text-[#868686]'} 2xl:text-xl text-base font-semibold`}>
                            {groupStage[props.data.countryId] === 0 ? 'Winner' : 'Eliminated'}
                        </span>
                        </div>
                    ) : (
                        <div className={`flex items-center gap-x-5 justify-end col-span-3`}>
                            <div className={`text-white font-semibold flex items-center gap-x-3 justify-center`}>
                                <img src={maticIcon} alt="matic_icon" className={`w-7`}/>
                                <div className={'flex flex-col'}>
                                    <span className={'text-[10px] text-zinc-500 leading-[12px]'}>MATIC</span>
                                    <span
                                        className={'text-[19px] text-white font-semibold leading-[20px]'}>
                                {mintPrice[props.data.countryId] > 0 ? mintPrice[props.data.countryId] : '0.00'}
                                        {/*{getPrice(props.data.supply) > 0 ? Number(getPrice(props.data.supply)).toFixed(2) : '0.00'}*/}
                            </span>
                                </div>
                            </div>
                            {props.data.supply > 0 && mintData[4] <= props.data.supply ?
                                <div className="flex flex-row pl-[110px] pr-10">
                                    <span className="text-md text-xl font-bold text-zinc-400 uppercase">Sold</span>
                                </div> :
                                <>
                                    {(supplyByUser[props.data.countryId] > 0 && supplyByUser[props.data.countryId] >= mintData[3]) ?
                                        <div className="flex flex-row pl-[110px] pr-10">
                                            <span className="text-md text-xl font-bold text-zinc-400 uppercase">N/A</span>
                                        </div> :
                                        <div className={`flex flex-row gap-x-2 items-center `}>
                                            <Tooltip
                                                title={Items[props.data.countryId] > 0 ? 'Remove from cart' : 'Add to cart'}
                                                arrow={true} placement={'bottom'}>
                                                <button onClick={() => {
                                                    if (address && address !== '') {
                                                        Items[props.data.countryId] > 0 ? cartFunctions.useRemoveItem(address, props.data.countryId) : cartFunctions.useAddToCart(address, props.data.countryId, 1);
                                                    } else {
                                                        dispatch(show({
                                                            title: "Please connect wallet!",
                                                            body: '',
                                                            type: 'error'
                                                        }));
                                                    }
                                                }}
                                                        className={`${Items[props.data.countryId] > 0 ? 'bg-[#2D2730]' : 'bg-[#007AFF]'} text-lg h-10 text-white px-4 rounded-2xl gap-x-1 font-semibold flex flex-row items-center`}>
                                                    {Items[props.data.countryId] > 0 ?
                                                        <img src={minusIcon} className={'w-5'} alt={'flash-icon'}/>
                                                        : <img src={plusIcon} className={'w-5'} alt={'flash-icon'}/>}
                                                    <img src={CartIcon} className={'w-5'} alt={'cart-icon'}/>
                                                </button>
                                            </Tooltip>
                                            <Tooltip title={'Mint now'} arrow={true} placement={'bottom'}>
                                                <button onClick={() => {
                                                    if (address && address !== '') {
                                                        Items[props.data.countryId] == undefined && (cartFunctions.useAddToCart(address, props.data.countryId, 1));
                                                        dispatch(setWidgetStats(true));
                                                    } else {
                                                        dispatch(show({
                                                            title: "Please connect wallet!",
                                                            body: '',
                                                            type: 'error'
                                                        }));
                                                    }
                                                }}
                                                        className={'bg-[#3EB489] text-lg h-10 text-white px-5 rounded-full gap-x-1 font-semibold flex flex-row items-center '}>
                                                    <img src={flashIcon} className={'w-5'} alt={'flash-icon'}/>Mint
                                                </button>
                                            </Tooltip>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    )}

                </div>
        );
    }
;
