import React, {useState} from "react";
import "./SmallCard.css";
import {flags} from "../Flags";
import ticketIcon from "../../../assets/ticket.svg";
import ProgressBar from "@ramonak/react-progress-bar";
import maticIcon from "../../../assets/currencies/Polygon.svg";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {useCartFunctions} from "../../../services/CartFunctions";
import minusIcon from "../../../assets/Minus.svg";
import plusIcon from "../../../assets/Plus.svg";
import CartIcon from "../../../assets/cart.svg";
import {setLoadingStatus, setWidgetStats} from "../../../redux/reducer/widget";
import flashIcon from "../../../assets/flash.svg";
import giftIcon from "../../../assets/giftIcon.svg";
import trophyIcon from "../../../assets/trophy-1.svg";
import {Box, Modal, styled, Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import {mint_addr} from "../../../services/link.config";
import {NumericFormat} from "react-number-format";
import {show} from "../../../redux/reducer/notification";


export const SmallCard = (props: any) => {
        const dispatch = useAppDispatch();
        const address = useAppSelector(state => state.wallet.walletAddress);
        const Items = useAppSelector(state => state.widget.items);
        const cartFunctions = useCartFunctions();
        const mintData = useAppSelector(state => state.wallet.getMintData);
        const mintPrice = useAppSelector(state => state.wallet.getTokenPrice);
        const totalSupply = useAppSelector(state => state.wallet.totalSupply);
        let supplyByUser = useAppSelector(state => state.wallet.supplyByUser);
        const ownedNFTs = useAppSelector(state => state.wallet.ownedNFTs);

        if (address && address !== '') {
            dispatch(setLoadingStatus((totalSupply.length < 1 || supplyByUser.length < 1 || mintData.length < 1)));
        } else {
            dispatch(setLoadingStatus((totalSupply.length < 1 || mintData.length < 1)));
            // dispatch(setLoadingStatus(false));
        }
        const mintLoadingStatus = useAppSelector(state => state.widget.loadingStatus);

        //eliminated group;
        const groupStage = useAppSelector(state => state.widget.groupStage);

        const [openPrizeModal, setOpenPrizeModal] = React.useState(false);
        const handleOpenPrizeModal = () => setOpenPrizeModal(true);
        const handleClosePrizeModal = () => setOpenPrizeModal(false);

        let firstPlacePrize;
        let secondPlacePrize;
        let thirdPlacePrize;
        let fourthPlacePrize;
        let qfPlacePrize;
        let r16PlacePrize;
        let grpPlacePrize;
        if (props.data.supply > 0) {
            firstPlacePrize = mintData[1] * 0.5 / props.data.supply;
            secondPlacePrize = mintData[1] * 0.175 / props.data.supply;
            thirdPlacePrize = mintData[1] * 0.1 / props.data.supply;
            fourthPlacePrize = mintData[1] * 0.05 / props.data.supply;
            qfPlacePrize = mintData[1] * 0.04 / (props.data.supply * 4);
            r16PlacePrize = mintData[1] * 0.025 / (props.data.supply * 8);
            grpPlacePrize = mintData[1] * 0.01 / (props.data.supply * 16);
        } else {
            firstPlacePrize = mintData[1] * 0.5;
            secondPlacePrize = mintData[1] * 0.175;
            thirdPlacePrize = mintData[1] * 0.1;
            fourthPlacePrize = mintData[1] * 0.05;
            qfPlacePrize = mintData[1] * 0.04 / 4;
            r16PlacePrize = mintData[1] * 0.025 / 8;
            grpPlacePrize = mintData[1] * 0.01 / 16;
        }

        const prizeList = [firstPlacePrize, secondPlacePrize, thirdPlacePrize, fourthPlacePrize, qfPlacePrize, r16PlacePrize, grpPlacePrize];
        const prizeText = ['1st Place Prize', '2nd Place Prize', '3rd Place Prize', '4th Place Prize', 'Quarter Final Prize', 'Round 16 Prize', 'Group Stage Prize'];

        const getNftAmounts = (ownedNFTs: any) => {
            let amounts = 0;
            if (ownedNFTs.length > 0) {
                for (let nft of ownedNFTs) {
                    if (nft.contract.address.toLowerCase() === mint_addr.toLowerCase() && nft.tokenId == props.data.countryId) {
                        amounts = nft.balance;
                    }
                }
                return amounts;
            } else {
                return 0;
            }
        }

        const trophyElement = <img src={trophyIcon} className={'w-6'} alt={'trophy-icon'}/>

        const modalStyle = {
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#17111A',
            border: '1px solid rgb(113 113 122)',
            borderRadius: '15px',
            width: '250px',
            boxShadow: 'none',
            outline: 'none'
        };
        return (address !== '' && mintLoadingStatus ? <>
                    <div
                        style={{border: props.rowTab === props.data.country ? "1px solid #007AFF" : ""}}
                        className={`poppin-text row border border-zinc-700 rounded-xl px-5 py-3 flex flex-col gap-y-2 animate-pulse relative`}
                    >
                        <div
                            className={`flex items-center gap-x-2 bg-zinc-800 border-b border-b-zinc-500 h-20 w-full pb-2 rounded-xl`}>
                        </div>
                    </div>
                </> :
                <>
                    <div
                        style={{border: props.rowTab === props.data.country ? "1px solid #007AFF" : ""}}
                        className={`poppin-text row border ${groupStage[props.data.countryId] === 0 ? 'border-[#007AFF] bg-[#0B213F]' : 'border-zinc-700 bg-[#1f1822]'} rounded-xl px-5 py-3 flex flex-col gap-y-2 relative`}
                    >
                        <div className={`flex items-center gap-x-2 grid grid-cols-7 border-b border-b-zinc-500 pb-2`}>
                            <div className="flex flex-row  gap-y-1 items-center col-span-2">
                    <span
                        className={`rounded-md font-semibold text-white text-left text-xl w-8`}>{groupStage[props.data.countryId] === 0 ? trophyElement : props.Number}</span>
                                <img className={`w-12 rounded-[6px]`} src={flags[props.data.country]}
                                     alt={props.data.country}/>
                            </div>
                            <div className="flex flex-col items-center col-span-5">
                                <div className={`flex flex-row justify-between items-center w-full`}>
                                    <div className={`flex flex-row items-center gap-x-2 text-white text-base`}>
                                        {props.data.country}
                                        {(address && address !== '') &&
                                        <p className={`flex flex-row text-[#FAFF00] text-base ${getNftAmounts(ownedNFTs) > 0 ? '' : 'hidden'}`}>
                                            <img src={ticketIcon} alt={'ticket-icon'}
                                                 className={'w-4'}/> x{getNftAmounts(ownedNFTs)}</p>}
                                    </div>
                                    <div
                                        className={'text-white text-sm'}>{totalSupply[props.data.countryId] > -1 ? totalSupply[props.data.countryId] + '/' + mintData[4] : 'loading...'}</div>
                                </div>
                                <Tooltip
                                    title={totalSupply[props.data.countryId] > -1 ? totalSupply[props.data.countryId] + '/' + mintData[4] : 'loading...'}
                                    arrow={true} placement={'bottom'}>
                                    <div className={'flex flex-row items-center w-full justify-center col-span-3'}>
                                        <ProgressBar completed={`${totalSupply[props.data.countryId]}`}
                                                     isLabelVisible={false} maxCompleted={mintData[4] ? mintData[4] : 2000}
                                                     className={'w-full'} height={'12px'}
                                                     borderRadius="3px" bgColor={'#007AFF'} baseBgColor={'#192C4E'}/>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        {groupStage[props.data.countryId] > -1 ? (
                            <div className={`flex items-center gap-x-2 justify-between col-span-3 pl-2`}>
                                <span
                                    className={'text-[#868686] text-sm poppin-text font-semibold'}>{prizeText[groupStage[props.data.countryId]]}</span>
                                <div className={`text-white font-semibold flex items-center gap-x-1 justify-center`}>
                                    <img src={maticIcon} alt="matic_icon" className={`w-7`}/>
                                    <div className={'flex flex-col'}>
                                        <span className={'text-[10px] text-zinc-500 leading-[12px]'}>MATIC</span>
                                        <span
                                            className={'text-[15px] text-white font-semibold leading-[20px]'}><NumericFormat
                                            value={prizeList[groupStage[props.data.countryId]] ? (prizeList[groupStage[props.data.countryId]]).toFixed(2) : 1.00}
                                            decimalScale={2}
                                            fixedDecimalScale={true} displayType={'text'} thousandSeparator={true}/></span>
                                    </div>
                                </div>
                                <span
                                    className={`poppin-text ${groupStage[props.data.countryId] === 0 ? 'text-[#FECE00]' : 'text-[#868686]'} 2xl:text-xl text-base font-semibold`}>
                                    {groupStage[props.data.countryId] === 0 ? 'Winner' : 'Eliminated'}
                                </span>
                            </div>
                        ) : (
                            <div className={`flex items-center gap-x-5 justify-between col-span-3 pl-2`}>
                                <div className={`text-white font-semibold flex items-center gap-x-3 justify-center`}>
                                    <Modal open={openPrizeModal} onClose={handleClosePrizeModal}
                                           onClick={handleClosePrizeModal}>
                                        <Box sx={modalStyle}>
                                            <div
                                                className={`flex flex-col items-center p-3 gap-y-2 w-full poppin-text`}>
                                                <div className={'text-blue-500 text-base'}>Prize per NFT</div>
                                                <div className={'flex flex-row justify-between w-full'}>
                                                    <span className={'text-[#868686] text-sm'}>1st Place</span>
                                                    <span
                                                        className={'text-sm text-white'}>{firstPlacePrize ? (firstPlacePrize).toFixed(2) + ' MATIC' : 1.00}</span>
                                                </div>
                                                <div className={'flex flex-row justify-between w-full'}>
                                                    <span className={'text-[#868686] text-sm'}>2nd Place</span>
                                                    <span
                                                        className={'text-sm text-white'}>{secondPlacePrize ? (secondPlacePrize).toFixed(2) + ' MATIC' : 1.00}</span>
                                                </div>
                                                <div className={'flex flex-row justify-between w-full'}>
                                                    <span className={'text-[#868686] text-sm'}>3rd Place</span>
                                                    <span
                                                        className={'text-sm text-white'}>{thirdPlacePrize ? (thirdPlacePrize).toFixed(2) + ' MATIC' : 1.00}</span>
                                                </div>
                                                <div className={'flex flex-row justify-between w-full'}>
                                                    <span className={'text-[#868686] text-sm'}>4th place</span>
                                                    <span
                                                        className={'text-sm text-white'}>{fourthPlacePrize ? (fourthPlacePrize).toFixed(2) + ' MATIC' : 1.00}</span>
                                                </div>
                                                <div className={'flex flex-row justify-between w-full'}>
                                                    <span className={'text-[#868686] text-sm'}>Quarter Final</span>
                                                    <span
                                                        className={'text-sm text-white'}>{qfPlacePrize ? (qfPlacePrize).toFixed(2) + ' MATIC' : 1.00}</span>
                                                </div>
                                                <div className={'flex flex-row justify-between w-full'}>
                                                    <span className={'text-[#868686] text-sm'}>Round 16</span>
                                                    <span
                                                        className={'text-sm text-white'}>{r16PlacePrize ? (r16PlacePrize).toFixed(2) + ' MATIC' : 1.00}</span>
                                                </div>
                                                <div className={'flex flex-row justify-between w-full'}>
                                                    <span className={'text-[#868686] text-sm'}>Group  Stage</span>
                                                    <span
                                                        className={'text-sm text-white'}>{grpPlacePrize ? (grpPlacePrize).toFixed(2) + ' MATIC' : 1.00}</span>
                                                </div>
                                            </div>
                                        </Box>
                                    </Modal>
                                    <img src={giftIcon} alt="matic_icon" className={`w-6`}
                                         onClick={() => handleOpenPrizeModal()}/>

                                </div>
                                <div className={`text-white font-semibold flex items-center gap-x-2 justify-center`}>
                                    <img src={maticIcon} alt="matic_icon" className={`w-7`}/>
                                    <div className={'flex flex-col'}>
                                        <span className={'text-[10px] text-zinc-500'}>MATIC</span>
                                        <span
                                            className={'text-[15px] text-white font-semibold'}>
                                    {mintPrice[props.data.countryId] > 0 ? mintPrice[props.data.countryId] : mintData[2]}
                                            {/*{getPrice(props.data.supply) > 0 ? Number(getPrice(props.data.supply)).toFixed(2) : '0.00'}*/}
                                </span>
                                    </div>
                                </div>
                                {totalSupply[props.data.countryId] > 0 && mintData[4] <= totalSupply[props.data.countryId] ?
                                    <div className="flex flex-row pl-[90px] pr-10">
                                        <span className="text-md text-xl font-bold text-zinc-400 uppercase">Sold</span>
                                    </div> :
                                    <div className={`flex flex-row gap-x-2 `}>
                                        {(supplyByUser[props.data.countryId] > 0 && supplyByUser[props.data.countryId] >= mintData[3]) ?
                                            <div className="flex flex-row pl-[90px] pr-10">
                                            <span
                                                className="text-md text-xl font-bold text-zinc-400 uppercase">N/A</span>
                                            </div> :
                                            <>
                                                <button onClick={() => {
                                                    if (address && address !== '') {
                                                        Items[props.data.countryId] > 0 ? cartFunctions.useRemoveItem(address, props.data.countryId) : cartFunctions.useAddToCart(address, props.data.countryId, 1);
                                                    } else {
                                                        dispatch(show({
                                                            title: "Please connect wallet!",
                                                            body: '',
                                                            type: 'error'
                                                        }));
                                                    }
                                                }}
                                                        className={`${Items[props.data.countryId] > 0 ? 'bg-[#2D2730]' : 'bg-[#007AFF]'} text-lg h-10 text-white px-4 rounded-2xl gap-x-1 font-semibold flex flex-row items-center  justify-center`}>
                                                    {Items[props.data.countryId] > 0 ?
                                                        <img src={minusIcon} className={'w-5'} alt={'flash-icon'}/>
                                                        : <img src={plusIcon} className={'w-5'} alt={'flash-icon'}/>}
                                                    <img src={CartIcon} className={'w-5'} alt={'cart-icon'}/>
                                                </button>
                                                <button onClick={() => {
                                                    if (address && address !== '') {
                                                        Items[props.data.countryId] == undefined && (cartFunctions.useAddToCart(address, props.data.countryId, 1));
                                                        dispatch(setWidgetStats(true));
                                                    } else {
                                                        dispatch(show({
                                                            title: "Please connect wallet!",
                                                            body: '',
                                                            type: 'error'
                                                        }));
                                                    }
                                                }}
                                                        className={'bg-[#3EB489] text-lg h-10 text-white px-5 rounded-full gap-x-1 font-semibold flex flex-row items-center justify-center'}>
                                                    <img src={flashIcon} className={'w-5'} alt={'flash-icon'}/>Mint
                                                </button>
                                            </>
                                        }
                                    </div>
                                }
                            </div>
                        )}

                    </div>
                </>
        );
    }
;
