// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
    fs_apiKey,
    fs_appId,
    fs_authDomain, fs_measurementId,
    fs_messagingSenderId,
    fs_projectId,
    fs_storageBucket
} from "./firestore-provider";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: fs_apiKey,
    authDomain: fs_authDomain,
    projectId: fs_projectId,
    storageBucket: fs_storageBucket,
    messagingSenderId: fs_messagingSenderId,
    appId: fs_appId,
    measurementId: fs_measurementId
};

// Initialize Firebase Import
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export default db;

/// Don't forget, I am using firebase Cloud Firestore.!!!
