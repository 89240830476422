import {createSlice} from '@reduxjs/toolkit'

interface WidgetState {
    stats: boolean,
    items: any,
    cartStatus: any,
    loadingStatus:boolean,
    totalPrice: number,
    groupStage: any,
    round16Stage: any,
    quarterStage: any,
    semiStage: any,
    finalStage:any,
    priceFeed:any,
}

const initialState = {
    stats: false,
    items: {},
    loadingStatus:false,
    totalPrice:0,
    groupStage: {},
    round16Stage: {},
    quarterStage: {},
    semiStage: {},
    finalStage: {},
    priceFeed : {},
} as WidgetState

const widgetSlice = createSlice({
    name: 'widget Status',
    initialState,
    reducers: {
        setWidgetStats(state, action) {
            state.stats = action.payload;
        },
        setWidgetItems(state, action) {
            state.items = action.payload;
        },
        setWidgetCartStatus(state, action) {
            state.cartStatus = action.payload;
        },
        setLoadingStatus(state, action) {
            state.loadingStatus = action.payload;
        },
        setTotalPrice(state, action) {
            state.totalPrice = action.payload;
        },
        setGroupStage(state, action) {
            state.groupStage = action.payload;
        },
        setRound16Stage(state, action) {
            state.round16Stage = action.payload;
        },
        setQuarterStage(state, action) {
            state.quarterStage = action.payload;
        },
        setSemiStage(state, action) {
            state.semiStage = action.payload;
        },
        setFinalStage(state, action){
            state.finalStage = action.payload;
        },
        setPriceFeed(state, action) {
            state.priceFeed = action.payload;
        },

    },
})

export const {
    setWidgetStats,
    setWidgetItems,
    setWidgetCartStatus,
    setLoadingStatus,
    setTotalPrice,
    setGroupStage,
    setRound16Stage,
    setQuarterStage,
    setSemiStage,
    setFinalStage,
    setPriceFeed,
} = widgetSlice.actions
export default widgetSlice.reducer