import {useEffect, useState} from "react";
import {useAppSelector} from "../../hooks";

export const Timer = () => {
    const [currentTime, setCurrentTime] = useState(new Date().getTime());
    const mintData = useAppSelector(state => state.wallet.getMintData);
    const rewardData = useAppSelector(state => state.wallet.getRewardData);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().getTime())
        }, 1000)

        return () => clearInterval(interval)
    }, [])
    const seconds: any = Math.floor((mintData[5] * 1000 - currentTime) / 1000);
    const rewardSeconds: any = Math.floor((rewardData[0] * 1000 - currentTime) / 1000);
    let timeDays
    let timeHours
    let timeMinutes
    let timeSeconds

    if (seconds > 0) {
        // Calculating the days, hours, minutes and seconds left
        timeDays = Math.floor(seconds / (60 * 60 * 24))
        timeHours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60))
        timeMinutes = Math.floor((seconds % (60 * 60)) / 60)
        timeSeconds = Math.floor(seconds % 60)

        timeDays = timeDays < 10 ? `0${timeDays}` : timeDays
        timeHours = timeHours < 10 ? `0${timeHours}` : timeHours
        timeMinutes = timeMinutes < 10 ? `0${timeMinutes}` : timeMinutes
        timeSeconds = timeSeconds < 10 ? `0${timeSeconds}` : timeSeconds
    } else {
        if (rewardSeconds > 0) {
            timeDays = Math.floor(rewardSeconds / (60 * 60 * 24))
            timeHours = Math.floor((rewardSeconds % (60 * 60 * 24)) / (60 * 60))
            timeMinutes = Math.floor((rewardSeconds % (60 * 60)) / 60)
            timeSeconds = Math.floor(rewardSeconds % 60)

            timeDays = timeDays < 10 ? `0${timeDays}` : timeDays
            timeHours = timeHours < 10 ? `0${timeHours}` : timeHours
            timeMinutes = timeMinutes < 10 ? `0${timeMinutes}` : timeMinutes
            timeSeconds = timeSeconds < 10 ? `0${timeSeconds}` : timeSeconds
        } else {
            timeDays = '00'
            timeHours = '00'
            timeMinutes = '00'
            timeSeconds = '00'
        }
    }

    return (<>
        <div className={`flex flex-row items-center gap-x-1`}>
            <div
                className={`border border-zinc-700 rounded-xl text-white text-lg w-14 h-14 sm:w-12 sm:h-12 text-center flex flex-col justify-center`}>{timeDays}</div>
            <span className={`text-zinc-500 text-base`}>d</span>
            <div
                className={`border border-zinc-700 rounded-xl text-white text-lg w-14 h-14 sm:w-12 sm:h-12 text-center flex flex-col justify-center`}>{timeHours}</div>
            <span className={`text-zinc-500 text-base`}>h</span>
            <div
                className={`border border-zinc-700 rounded-xl text-white text-lg w-14 h-14 sm:w-12 sm:h-12 text-center flex flex-col justify-center`}>{timeMinutes}</div>
            <span className={`text-zinc-500 text-base`}>m</span>
            <div
                className={`border border-zinc-700 rounded-xl text-white text-lg w-14 h-14 sm:w-12 sm:h-12 text-center flex flex-col justify-center`}>{timeSeconds}</div>
            <span className={`text-zinc-500 text-base`}>s</span>
        </div>
    </>)
}