const mint_addr = (process.env.REACT_APP_MINT_ADDR as string);
const distribution_addr = (process.env.REACT_APP_DISTRIBUTION_ADDR as string);
const usdc_addr = (process.env.REACT_APP_USDC_ADDR as string);
const alchemy_id = (process.env.REACT_APP_ALCHEMY_ID as string);
const alchemy_rpc = (process.env.REACT_APP_RPC as string);
const twitter_link = (process.env.REACT_APP_TWITTER_LINK as string);
const discord_link = (process.env.REACT_APP_DISCORD_LINK as string);
const telegram_link = (process.env.REACT_APP_TELEGRAM_LINK as string);
const whitepaper_link = (process.env.REACT_APP_WHITEPAPER_LINK as string);


export {
    mint_addr,
    distribution_addr,
    alchemy_id,
    alchemy_rpc,
    twitter_link,
    discord_link,
    telegram_link,
    usdc_addr,
    whitepaper_link
}