import {useState} from "react";
import "./Navbar.css";
import {ConnectButton} from "@rainbow-me/rainbowkit";
import {useAppDispatch, useAppSelector} from "../../hooks";

import PolygonIcon from "../../assets/currencies/Polygon.svg";
import wallet_icon from "../../assets/wallet_icon.svg";
import qatarIcon from "../../assets/qatar logo.png";
import openSeaIcon from "../../assets/opensea-icon.svg";
import CartIcon from "../../assets/cart.svg";
import tgIcon from "../../assets/social/_Telegram.svg";
import dcIcon from "../../assets/social/_Discord.svg";
import twIcon from "../../assets/social/_Twitter.svg";
import wpIcon from "../../assets/social/Document.svg";
import {setLoadingStatus, setWidgetStats} from "../../redux/reducer/widget";
import {setWalletAddress, setWalletAmount} from "../../redux/reducer/wallet";
import {discord_link, telegram_link, twitter_link, whitepaper_link} from "../../services/link.config";
import {Tooltip} from "@mui/material";

export const Navbar = () => {
    const dispatch = useAppDispatch();
    const [showDropdown, setShowDropdown] = useState(false);
    const Items = useAppSelector(state => state.widget.items);
    let goerliBalance = useAppSelector(state => state.wallet.goerliBalance);
    const cartWidgetTrigger = () => {
        dispatch(setWidgetStats(true));
    }
    return (<>
        <div className={`flex flex-row items-center justify-between navbar-menu h-20 2xl:h-24 grid grid-cols-5`}>
            <div className={`flex flex-row sm:gap-x-2 items-center col-span-2`}>
                <img src={qatarIcon} className={`w-10 sm:w-12 h-14 sm:h-16`} alt={'qatar-icon'}/>
                <div className={`flex flex-col justify-start `}>
                    <span
                        className={'flex flex-row text-white text-[12px] sm:text-xs sm:text-2xl font-bold items-center baloo-text'}>sports<span
                        className={`baloo-text text-[#007AFF]`}>predict</span>.io

                    </span>
                    <span className={'flex flex-row text-white text-[10px] sm:text-xl items-center gap-x-1'}>World Cup Qatar 2022 Prediction NFT
                         <a
                             href={`https://opensea.io/collection/sportspredict-world-cup-qatar-prediction-nft`}
                             target={"_blank"}> <img
                             src={openSeaIcon} alt={'opensea-icon'} className={'w-6 hidden sm:block'}/></a>
                    </span>
                </div>
            </div>

            <div className="flex flex-row items-center justify-end h-14 col-span-3 gap-x-2">
                <div
                    className={'flex flex-row items-center justify-end hidden sm:grid gap-x-1 2xl:gap-x-2 grid-cols-2'}>
                    <div
                        className={'flex flex-row justify-center gap-x-5 border-2 border-zinc-700 bg-[#1F1822] rounded-xl py-2.5 max-w-[185px] w-[185px]'}>
                        <a href={telegram_link} target={"_blank"}><img src={tgIcon} alt={'tg-icon'} className={'w-9'}/>
                        </a>
                        <a href={discord_link} target={"_blank"}><img src={dcIcon} alt={'dc-icon'} className={'w-9'}/>
                        </a>
                        <a href={twitter_link} target={"_blank"}><img src={twIcon} alt={'tw-icon'} className={'w-9'}/>
                        </a>
                    </div>
                    <a href={whitepaper_link}
                       className={'flex flex-row items-center justify-center gap-x-1 border-2 border-zinc-700 bg-[#1F1822] rounded-xl py-3.5  max-w-[185px] w-[185px]'}
                       target={"_blank"}>
                        <img src={wpIcon} alt={'wp-icon'} className={'w-7'}/>
                        <span className={'text-white font-semibold text-base 2xl:text-lg'}>Whitepaper</span>
                    </a>
                </div>
                <div className={'flex flex-row items-center'}>
                    <ConnectButton.Custom>
                        {({
                              account,
                              chain,
                              openAccountModal,
                              openChainModal,
                              openConnectModal,
                              authenticationStatus,
                              mounted,
                          }) => {
                            // Note: If your app doesn't use authentication, you
                            // can remove all 'authenticationStatus' checks
                            const ready = mounted;
                            const connected =
                                ready &&
                                account &&
                                chain;
                            return (
                                <div
                                    {...(!ready && {
                                        'aria-hidden': true,
                                        'style': {
                                            opacity: 0,
                                            pointerEvents: 'none',
                                            userSelect: 'none',
                                        },
                                    })}
                                >
                                    {(() => {
                                        if (!connected) {
                                            dispatch(setWalletAddress(''));
                                            return (
                                                <button
                                                    onClick={openConnectModal}
                                                    className="wallet-btn h-14 w-36 sm:w-44 rounded-lg font-semibold text-base text-white"
                                                >
                                                    Connect Wallet
                                                </button>
                                            );
                                        }
                                        if (chain.unsupported) {
                                            dispatch(setWalletAddress(''));
                                            return (
                                                <button onClick={openChainModal} type="button"
                                                        className={`switch-btn  h-14 w-36 sm:w-44 rounded-lg font-semibold text-base text-white`}>
                                                    Switch Network
                                                </button>
                                            );
                                        }
                                        dispatch(setWalletAddress(account.address));
                                        dispatch(setWalletAmount(Number(account.balanceFormatted)));
                                        return (
                                            <div className={`flex flex-row gap-x-1 sm:gap-x-2`}>
                                                <div style={{display: 'flex', gap: 12}}>
                                                    <Tooltip title={'Open Wallet Menu'} arrow={true}
                                                             placement={'bottom'}>
                                                        <button onClick={openAccountModal} type="button"
                                                                className={`poppin-text border-2 border-zinc-700 bg-[#1F1822]  flex items-center gap-x-4 ${showDropdown ? "rounded-tl-xl rounded-tr-xl rounded-bl-xl " : "rounded-xl"} py-1 sm:py-2`}
                                                        >

                                                            <div className="flex items-center gap-x-3 sm:pl-5 pl-2">
                                                                <img src={PolygonIcon} alt="dollar_icon"
                                                                     className={`w-8 h-8 sm:w-10 sm:h-10}`}/>
                                                                <div className="flex flex-col items-start">
                                                        <span
                                                            className="text-[10px] sm:text-xs font-semibold text-zinc-400">MATIC</span>
                                                                    <p className={`text-xs sm:text-base text-white font-semibold `}>
                                                                        {account.displayBalance
                                                                            ? ` ${goerliBalance.toFixed(3)}`
                                                                            // ? ` ${account.displayBalance.split(' ')[0]}`
                                                                            : '0'}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="cursor-pointer flex items-center gap-x-4 border-l-2 border-zinc-700 pl-2 sm:pl-5 relative pr-2 sm:pr-5 relative"
                                                            >
                                                                <div
                                                                    className="flex flex-col items-start hidden sm:block">
                                                                    <p className={`text-white font-semibold text-base}`}>{account.displayName}</p>
                                                                    <div className="flex items-center gap-x-1">
                                                                        <div
                                                                            className="w-2 h-2 rounded-full bg-green-500"></div>
                                                                        <span
                                                                            className={`text-xs font-semibold text-zinc-400`}>Connected</span>
                                                                    </div>
                                                                </div>
                                                                <div className={'relative'}>
                                                                    <img src={wallet_icon} alt="wallet_icon"
                                                                         className={`w-10`}/>
                                                                    <div
                                                                        className="block sm:hidden w-3 h-3 rounded-full bg-green-500 absolute top-0 right-0 border border-2 border-[#1f1822]"></div>
                                                                </div>

                                                            </div>
                                                        </button>
                                                    </Tooltip>
                                                </div>
                                                <Tooltip title={'Open Minting Cart'} arrow={true} placement={'bottom'}>
                                                    <button
                                                        className={`flex poppin-text border-2 border-zinc-700 items-center rounded-xl px-2 sm:px-4 bg-[#1f1822]`}
                                                        onClick={() => {
                                                            cartWidgetTrigger()
                                                        }}>
                                                        <div className={'relative'}>
                                                            <img src={CartIcon} className={'w-7'} alt={'cart-icon'}/>
                                                            <div
                                                                className={`${Object.keys(Items).length ? 'block' : 'hidden'} w-[22px] h-[22px] rounded-full bg-[#007AFF] absolute top-[-6px] right-[-6px] border border-2 border-[#1F1822] text-[12px] text-white font-bold`}>{Object.keys(Items).length ? Object.keys(Items).length : 0}</div>
                                                        </div>
                                                    </button>
                                                </Tooltip>
                                            </div>

                                        );
                                    })()}
                                </div>
                            );
                        }}
                    </ConnectButton.Custom>
                </div>
            </div>
        </div>
    </>)
}