import {useAppDispatch, useAppSelector} from "../../hooks";
import {Tooltip} from "@mui/material";
import {NumericFormat} from 'react-number-format';
import trophyIcon from "../../assets/trophy.svg"
import infoIcon from "../../assets/info-circle.svg";
import giftGoldIcon from "../../assets/gift-gold.svg";
import PolygonIcon from "../../assets/currencies/Polygon.svg";
import {setPrizeCartStatus} from "../../redux/reducer/prizeCart";
import {useWeb3Functions} from "../../services/useWeb3Functions";
import {mint_addr} from "../../services/link.config";
import ScaleLoader from "react-spinners/ScaleLoader";
import * as React from "react";
import {useEffect, useState} from "react";

export const PrizePool = (props: any) => {
    const dispatch = useAppDispatch();
    const address = useAppSelector(state => state.wallet.walletAddress);
    const mintData = useAppSelector(state => state.wallet.getMintData);
    const maticPrice = useAppSelector(state => state.wallet.maticPrice);
    const approveStatus = useAppSelector(state => state.prizeCart.approveStatus);
    let poolPrice = mintData[1] * maticPrice;
    const web3Functions = useWeb3Functions();
    const approved = useAppSelector(state => state.wallet.approved);
    const ownedNFTs = useAppSelector(state => state.wallet.ownedNFTs);
    const rewardData = useAppSelector(state => state.wallet.getRewardData);
    const rewardStartTime = rewardData[0];

    const prizeCartTrigger = () => {
        dispatch(setPrizeCartStatus(true));
    }

    const [currentTime, setCurrentTime] = useState(Number(new Date().getTime()) / 1000);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(Number(new Date().getTime()) / 1000)
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    const getNftAmounts = (ownedNFTs: any) => {
        let amounts = 0;
        if (ownedNFTs.length > 0) {
            for (let nft of ownedNFTs) {
                if (nft.contract.address.toLowerCase() == mint_addr.toLowerCase()) {
                    amounts = nft.balance;
                }
            }
            return amounts;
        } else {
            return 0;
        }
    }

    return (<>
            <div className="flex flex-col items-center gap-x-4 justify-center mt-24 mb-5 relative">
                <div className={'flex items-center gap-x-4 justify-center sm:mb-2'}>
                    <div className={`text-center gap-x-2`}>
                        <img src={trophyIcon} alt={'trophy-icon'} className={'w-24 sm:w-32'}/>
                    </div>
                    <div className={`flex flex-col`}>
                    <span className={`flex text-white text-[20px] sm:text-2xl font-semibold gap-x-2 items-center`}>Prize Pool
                        <Tooltip title={'Total prize pool in MATIC and USD (approximate) for all minted NFTs'}
                                 arrow={true} placement={'right'}>
                            <img src={infoIcon} className={'w-4'} alt={'info-icon'}/>
                        </Tooltip>
                        </span>
                        <span className={`text-[#FECE00] text-[40px] sm:text-[60px] font-semibold leading-none`}>
                        <NumericFormat
                            value={mintData[1]} decimalScale={2} displayType={'text'}
                            fixedDecimalScale={true} thousandSeparator={true}/>
                    </span>
                        <span className={`text-zinc-500 text-[15px] sm:text-[22px]`}>
                        <NumericFormat value={poolPrice} decimalScale={2} displayType={'text'} fixedDecimalScale={true}
                                       thousandSeparator={true} prefix={'~$'}/>
                    </span>
                    </div>
                </div>
                {getNftAmounts(ownedNFTs) > 0 && (
                    <div
                        className={'sm:absolute sm:right-0 sm:bottom-0 flex flex-col sm:items-end items-center mt-3 sm:mt-0'}>
                        {rewardStartTime > currentTime && (
                            <span
                                className={'poppin-text font-semibold text-xs text-zinc-500 text-right flex justify-end mb-2'}>
                                Claiming will start at<br className={'sm:block hidden'}/> 19.12.2022, 12:00PM UTC
                            </span>
                        )}

                        {(address && address !== '') &&
                        <span
                            className={`poppin-text border-2 border-[#C8A323] bg-[#1F1822]  flex items-center gap-x-4 py-1 sm:py-2 rounded-xl w-fit `}>
                            <div className="flex items-center gap-x-3 sm:pl-3 px-3">
                                <div className={'flex items-center gap-x-3 pl-1 '}>
                                    <img src={giftGoldIcon} alt="gift_icon" className={`w-8 h-8 sm:w-10 sm:h-10}`}/>
                                </div>
                                <div className="flex items-center gap-x-3 pl-3 border-l-2 border-l-[#C8A323]">
                                    <img src={PolygonIcon} alt="dollar_icon"
                                         className={`w-8 h-8 sm:w-10 sm:h-10`}/>
                                    <div className="flex flex-col items-start">
                                        <span
                                            className="text-[10px] sm:text-xs font-semibold text-[#C8A323]">MATIC</span>
                                        <p className={`text-xs sm:text-base text-white font-semibold `}>
                                            <NumericFormat
                                                value={props.rewardAmount()} decimalScale={2} displayType={'text'}
                                                fixedDecimalScale={true} thousandSeparator={true}/>
                                        </p>
                                    </div>
                                </div>
                                {rewardStartTime < currentTime && (
                                    <button
                                        className={'bg-[#C8A323] rounded-xl px-5 py-3 text-white font-semibold text-[16px] flex flex-row'}
                                        onClick={() => {
                                            if (approved) {
                                                prizeCartTrigger()
                                            } else {
                                                web3Functions.approveNFTs();
                                            }
                                        }}
                                        disabled={approveStatus}
                                    > Claim Reward &nbsp;
                                        <ScaleLoader color={'#fff'} loading={approveStatus} height={22} width={2}/>
                                    </button>
                                )}
                            </div>
                        </span>
                        }
                    </div>
                )}
            </div>
        </>
    );
};
