import React, {useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {flags, mappingData} from "../AllTabContent/Flags";
import maticIcon from "../../assets/currencies/Polygon.svg";
import infoIcon from "../../assets/info-circle-zinc.svg";
import {Tooltip} from "@mui/material";
import {NumericFormat} from "react-number-format";
import {setPrizeCartStatus} from "../../redux/reducer/prizeCart";
import giftGoldIcon from "../../assets/gift-gold.svg";
import {Distribution} from "./Distribution";
import ticketIcon from "../../assets/ticket.svg";

export const PrizeCart = (props: any) => {
    const dispatch = useAppDispatch();
    const refArea: any = useRef();
    const mintData = useAppSelector(state => state.wallet.getMintData);
    const totalSupply = useAppSelector(state => state.wallet.totalSupply);
    const purchasedNFTs = useAppSelector(state => state.prizeCart.purchasedNFTs);
    const groupStage = useAppSelector(state => state.widget.groupStage);
    const totalPrice = useAppSelector(state => state.prizeCart.totalPrice);
    const maticPrice = useAppSelector(state => state.wallet.maticPrice);
    const prizeText = ['1st Place Prize', '2nd Place Prize', '3rd Place Prize', '4th Place Prize', 'Quarter Final Prize', 'Round 16 Prize', 'Group Stage Prize'];
    const divisions = [0.5, 0.175, 0.1, 0.05, 0.04, 0.025, 0.01];
    const divisionTeams = [1, 1, 1, 1, 4, 8, 16];
    const prizeWidgetClose = () => {
        dispatch(setPrizeCartStatus(false));
    }

    const prizePrice = (divisionLevel: number, countryId: number, amounts: number) => {
        if (totalSupply[countryId] > 0) {
            if (divisions[divisionLevel] > -1) {
                return mintData[1] * divisions[divisionLevel] * amounts / (totalSupply[countryId] * divisionTeams[divisionLevel]);
            } else {
                return mintData[1] * divisions[3] * amounts / totalSupply[countryId];
            }
        } else {
            return 0;
        }
    }

    return (
        <>
            <div
                className="poppin-text overflow-y-auto overflow-x-hidden fixed top-0 right-0 z-50 w-full h-full flex justify-end">
                <div className="relative p-4 w-full 2xl:max-w-lg sm:max-w-md max-w-sm h-full">
                    <div
                        className="relative mobile-cart rounded-t-[30px] sm:rounded-2xl bg-[#1F1822] h-full border sm:border-2 border-[#C8A323] p-4"
                        ref={refArea}>
                        <div
                            className="flex justify-between items-center p-4 pt-0 rounded-t border-b border-[#C8A323] relative">
                            <p className="flex flex-row text-xl font-semibold gap-x-2 text-white items-center">
                                <img src={giftGoldIcon} className={'w-7'}/> Prize
                                <Tooltip title={'Here is Prize Reward Cart'} arrow={true} placement={'bottom'}>
                                    <img src={infoIcon} alt={'info-icon'} className={'w-4'}/>
                                </Tooltip>
                            </p>
                            <button type="button" onClick={() => {
                                prizeWidgetClose()
                            }}
                                    className="text-zinc-400 font-bold bg-transparent hover:text-zinc-100 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center absolute right-0"
                                    data-modal-toggle="defaultModal">
                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                          clipRule="evenodd"></path>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="flex flex-col py-5">
                            <div className={'flex flex-row justify-between w-full mb-2'}>
                                <span
                                    className={`text-white text-sm font-semibold`}>{purchasedNFTs.length ? purchasedNFTs.length : 0} Item(s)</span>
                            </div>
                            <div
                                className={'flex flex-col gap-y-3 py-5 w-full overflow-y-auto max-h-[40vh] sm:max-h-[65vh]'}>
                                {purchasedNFTs.length > 0 ? purchasedNFTs.map((data: any, index: any) => (
                                    <div className={'flex flex-row items-center justify-between'} key={index + 1}>
                                        <div className={`flex flex-row items-center gap-x-2`}>
                                            <div className={'flex flex-row gap-x-2 items-center text-white'}>
                                                <img src={flags[mappingData[data.tokenId]]} alt={'flag'}
                                                     className={'w-8 rounded'}/>
                                                <div className={'flex flex-row items-center gap-x-2'}>
                                                    <span
                                                        className={'text-white text-[17px] font-semibold'}>{mappingData[data.tokenId]}</span>
                                                    <span
                                                        className={'text-zinc-500 text-[15px] font-semibold'}>{prizeText[groupStage[data.tokenId]] ? prizeText[groupStage[data.tokenId]] : 'Not confirmed'}</span>
                                                    <span
                                                        className={`flex flex-row text-[#FAFF00] text-xs ${Number(data.balance) > 0 ? '' : 'hidden'}`}>
                                                        <img src={ticketIcon} alt={'ticket-icon'}
                                                             className={'w-4'}/> &nbsp;x {data.balance}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'flex flex-row items-center justify-center gap-x-3'}>
                                            <div
                                                className={`text-white font-semibold flex items-center gap-x-1 justify-center`}>
                                                <img src={maticIcon} alt="matic_icon" className={`w-6`}/>
                                                <div className={'flex flex-col'}>
                                                    <span
                                                        className={'text-[10px] leading-[12px] text-zinc-500'}>MATIC</span>
                                                    <span
                                                        className={'text-[15px] leading-[16px] text-white font-semibold'}>
                                                        <NumericFormat
                                                            value={prizePrice(groupStage[data.tokenId], data.tokenId, Number(data.balance))}
                                                            decimalScale={3}
                                                            displayType={'text'}
                                                            fixedDecimalScale={true} thousandSeparator={true}
                                                            prefix={''}/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) : (
                                    <div
                                        className={'flex flex-row items-center text-center text-zinc-400 justify-center'}>
                                        There is no item.
                                    </div>
                                )}
                            </div>
                            <div
                                className={'py-5 flex flex-row justify-between border-t border-[#C8A323] items-center'}>
                                <div className={'flex text-white text-lg font-semibold'}>
                                    Total Price
                                </div>
                                <div className={`text-white font-semibold flex items-center gap-x-1 justify-center`}>
                                    <img src={maticIcon} alt="matic_icon" className={`w-8`}/>
                                    <div className={'flex flex-col'}>
                                        <span className={'text-[10px] text-zinc-500'}>MATIC</span>
                                        <span
                                            className={'text-[15px] leading-[17px] text-white font-semibold'}><NumericFormat
                                            value={totalPrice} decimalScale={2} displayType={'text'}
                                            fixedDecimalScale={true} thousandSeparator={true}/></span>
                                        <span
                                            className={'text-[10px] leading-[12px] text-zinc-500 font-semibold'}>
                                            <NumericFormat
                                                value={totalPrice * maticPrice} decimalScale={2}
                                                displayType={'text'}
                                                fixedDecimalScale={true} thousandSeparator={true} prefix={'~$'}/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute bottom-0 left-0 right-0 w-full  flex items-center p-6 space-x-2">
                            <Distribution/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}