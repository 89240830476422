import {useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import {ContentRow} from "./Rows/ContentRow";
import {SmallCard} from "./SmallCard/SmallCard";
import "./AllTabContent.css";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useCartFunctions} from "../../services/CartFunctions";
import {useWeb3Functions} from "../../services/useWeb3Functions";
import {setWalletAddress} from "../../redux/reducer/wallet";
import {useFetchHolders} from "../../Web3/nft-owner";
import {useEliminateMange} from "../../services/EliminateMange";

type CountryType =
    "Argentina"
    | "Australia"
    | "Belgium"
    | "Brazil"
    | "Cameroon"
    | "Canada"
    | "Costa Rica"
    | "Croatia"
    | "Denmark"
    | "Ecuador"
    | "England"
    | "France"
    | "Germany"
    | "Ghana"
    | "Iran"
    | "Japan"
    | "Mexico"
    | "Morocco"
    | "Netherlands"
    | "Poland"
    | "Portugal"
    | "Qatar"
    | "Saudi Arabia"
    | "Senegal"
    | "Serbia"
    | "South Korea"
    | "Spain"
    | "Switzerland"
    | "Tunisia"
    | "Uruguay"
    | "USA"
    | "Wales"

const countryData = {
    "Argentina": 0,
    "Australia": 1,
    "Belgium": 2,
    "Brazil": 3,
    "Cameroon": 4,
    "Canada": 5,
    "Costa Rica": 6,
    "Croatia": 7,
    "Denmark": 8,
    "Ecuador": 9,
    "England": 10,
    "France": 11,
    "Germany": 12,
    "Ghana": 13,
    "Iran": 14,
    "Japan": 15,
    "Mexico": 16,
    "Morocco": 17,
    "Netherlands": 18,
    "Poland": 19,
    "Portugal": 20,
    "Qatar": 21,
    "Saudi Arabia": 22,
    "Senegal": 23,
    "Serbia": 24,
    "South Korea": 25,
    "Spain": 26,
    "Switzerland": 27,
    "Tunisia": 28,
    "Uruguay": 29,
    "USA": 30,
    "Wales": 31,
}

// console.log(`"${Object.keys(countryData).map(it => it).join('"|"')}"`)

export const AllTabContent = () => {
    const dispatch = useAppDispatch();
    const matches1 = useMediaQuery("(min-width:1024px)");
    const matches4 = useMediaQuery("(max-width:1340px)");
    const matches5 = useMediaQuery("(max-width:1160px)");
    const matches3 = useMediaQuery("(max-width:1450px)");
    const matches2 = useMediaQuery("(max-width:1800px)");
    const [showModal, setShowModal] = useState(false);
    const [popupStatus, setPopupStatus] = useState(33);
    const totalSupply = useAppSelector(state => state.wallet.totalSupply);

    const [rowTab, setRowTab] = useState("");
    const handleResize = () => {
        setRowTab("");
    };
    const address = useAppSelector(state => state.wallet.walletAddress);
    const mintLoadingStatus = useAppSelector(state => state.widget.loadingStatus);
    const prizeCartStatus = useAppSelector(state => state.prizeCart.state);
    //eliminated group;
    const groupStage = useAppSelector(state => state.widget.groupStage);

    const cartFunctions = useCartFunctions();
    const web3Functions = useWeb3Functions();
    const fetchHolders = useFetchHolders();
    const eliminateManage = useEliminateMange();

    useEffect(() => {
        web3Functions.getMintData();
        web3Functions.getRewardData();
        web3Functions.getTokenPrice();
        web3Functions.getTotalSupply();
        web3Functions.getMaticPrice();
        fetchHolders.runFetch();
        cartFunctions.getItems();
        web3Functions.getSupplyByUser();
        web3Functions.GoerliBalance();

        // eliminated stages ==
        eliminateManage.useGroupStage();
        eliminateManage.usePriceFeed();
    }, [address, mintLoadingStatus, prizeCartStatus]);

    window.addEventListener("resize", handleResize);

    const promotedCountryMap = Object.keys(countryData).filter((country: string) => {
        const countryId: number = Number(countryData[country as CountryType])
        return groupStage[countryId] === undefined;
    }).map((country: string) => {
        const countryId: number = Number(countryData[country as CountryType])
        return {
            supply: totalSupply[countryId],
            country: country,
            countryId: countryId
        }
    }).sort((a: any, b: any) => a.supply > b.supply ? -1 : a.supply < b.supply ? 1 : 0);
    const eliminatedCountryMap = Object.keys(countryData).filter((country: string) => {
        const countryId: number = Number(countryData[country as CountryType])
        return groupStage[countryId] !== undefined;
    }).map((country: string) => {
        const countryId: number = Number(countryData[country as CountryType])
        return {
            supply: totalSupply[countryId],
            country: country,
            countryId: countryId,
            stage: groupStage[countryId]
        }
    }).sort((a: any, b: any) => {
        if (a.stage === b.stage) {
            return a.supply > b.supply ? -1 : 1;
        } else {
            return a.stage < b.stage ? -1 : 1
        }
    });
    const countrySupplyMap = promotedCountryMap.concat(eliminatedCountryMap);
    // const countrySupplyMap = Object.keys(countryData)
    //     .map((country: string) => {
    //         const countryId: number = Number(countryData[country as CountryType])
    //         return {
    //             supply: totalSupply[countryId],
    //             country: country,
    //             countryId: countryId
    //         }
    //     })
    //     .sort((a: any, b: any) => a.supply > b.supply ? -1 : a.supply < b.supply ? 1 : 0)

    return (
        <div
            className={`flex flex-row ${
                matches2
                    ? matches3
                    ? matches4
                        ? matches5
                            ? "gap-x-20"
                            : "gap-x-14"
                        : "gap-x-10"
                    : "gap-x-8"
                    : "gap-x-5"
            }`}
        >
            <div className={`hidden sm:flex flex-col gap-y-4 w-full`}>
                {countrySupplyMap.map((it: any, ind: any) => (
                    <ContentRow
                        setShowModal={setShowModal}
                        data={it}
                        key={ind + 1}
                        Number={ind + 1}
                        rowTab={rowTab}
                        setRowTab={setRowTab}
                    ></ContentRow>
                ))}
            </div>
            <div className={`flex sm:hidden flex-col gap-y-4 w-full`}>
                {countrySupplyMap.map((it: any, ind: any) => (
                    <SmallCard
                        setShowModal={setShowModal}
                        data={it}
                        key={ind + 1}
                        Number={ind + 1}
                        rowTab={rowTab}
                        setRowTab={setRowTab}
                        popupStatus={popupStatus}
                        setPopupStatus={setPopupStatus}
                    ></SmallCard>
                ))}
            </div>
        </div>
    );
};
