import React from 'react';
import {createSlice} from '@reduxjs/toolkit'
import {toast} from "react-toastify";

const notify = (title: any, body: string, type: any) => toast(<><span className="notification-title">{title}</span></>, {
    type: type,
    className: 'toast--' + type,
})

interface NotificationState {
    show: boolean
}

const initialState = {show: false} as NotificationState

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        show(state, action) {
            const {title, body, type} = action.payload
            notify(title, body, type)
        },
    },
})

export const {show} = notificationSlice.actions
export default notificationSlice.reducer