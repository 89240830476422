import {configureStore} from '@reduxjs/toolkit'

import notificationReducer from '../redux/reducer/notification'
import matchDataReducer from '../redux/reducer/data'
import walletReducer from '../redux/reducer/wallet';
import widgetReducer from '../redux/reducer/widget';
import prizeCartReducer from '../redux/reducer/prizeCart';

const reducer = {
    notification: notificationReducer,
    matchData: matchDataReducer,
    wallet: walletReducer,
    widget: widgetReducer,
    prizeCart: prizeCartReducer
}

const preloadedState = {
    notification: {
        show: false
    }
}

export const store = configureStore({
    reducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState,
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch