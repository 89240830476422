import brazilFlag from '../../assets/flags/country=Brazil.svg';
import argentinaFlag from '../../assets/flags/country=Argentina.svg';
import germanyFlag from '../../assets/flags/country=Germany.svg';
import portugalFlag from '../../assets/flags/country=Portugal.svg';
import polandFlag from '../../assets/flags/country=Poland.svg';
import spainFlag from '../../assets/flags/country=Spain.svg';
import belgiumFlag from '../../assets/flags/country=Belgium.svg';
import franceFlag from '../../assets/flags/country=France.svg';
import denmarkFlag from '../../assets/flags/country=Denmark.svg';
import switzerlandFlag from '../../assets/flags/country=Switzerland.svg';
import australiaFlag from '../../assets/flags/country=Australia.svg';
import saudiFlag from '../../assets/flags/country=Saudi Arabia.svg';
import englandFlag from '../../assets/flags/country=England.svg';
import walesFlag from '../../assets/flags/country=Wales.svg';
import moroccoFlag from '../../assets/flags/country=Morocco.svg';
import serbiaFlag from '../../assets/flags/country=Serbia.svg';
import tunisiaFlag from '../../assets/flags/country=Tunisia.svg';
import usaFlag from '../../assets/flags/country=USA.svg';
import mexicoFlag from '../../assets/flags/country=Mexico.svg';
import japanFlag from '../../assets/flags/country=Japan.svg';
import iranFlag from '../../assets/flags/country=Iran.svg';
import ghanaFlag from '../../assets/flags/country=Ghana.svg';
import canadaFlag from '../../assets/flags/country=Canada.svg';
import uruguayFlag from '../../assets/flags/country=Uruguay.svg';
import cameroonFlag from '../../assets/flags/country=Cameroon.svg';
import costaFlag from '../../assets/flags/country=Costa Rica.svg';
import croatiaFlag from '../../assets/flags/country=Croatia.svg';
import southFlag from '../../assets/flags/country=South Korea.svg';
import ecuadorFlag from '../../assets/flags/country=Ecuador.svg';
import netherlandsFlag from '../../assets/flags/country=Netherlands.svg';
import qatarFlag from '../../assets/flags/country=Qatar.svg';
import senegalFlag from '../../assets/flags/country=Senegal.svg';

// const flags: any = {
//     "Brazil": brazilFlag,
//     "Argentina": argentinaFlag,
//     "Germany": germanyFlag,
//     "Portugal": portugalFlag,
//     "Poland ": polandFlag,
//     "Spain": spainFlag,
//     "Belgium": belgiumFlag,
//     "France": franceFlag,
//     "Denmark": denmarkFlag,
//     "Switzerland": switzerlandFlag
// }

const flags: any = {
    "Argentina": argentinaFlag,
    "Australia": australiaFlag,
    "Belgium": belgiumFlag,
    "Brazil": brazilFlag,
    "Cameroon": cameroonFlag,
    "Canada": canadaFlag,
    "Costa Rica": costaFlag,
    "Croatia": croatiaFlag,
    "Denmark": denmarkFlag,
    "Ecuador": ecuadorFlag,
    "England": englandFlag,
    "France": franceFlag,
    "Germany": germanyFlag,
    "Ghana": ghanaFlag,
    "Iran": iranFlag,
    "Japan": japanFlag,
    "Mexico": mexicoFlag,
    "Morocco": moroccoFlag,
    "Netherlands": netherlandsFlag,
    "Poland": polandFlag,
    "Portugal": portugalFlag,
    "Qatar": qatarFlag,
    "Saudi Arabia": saudiFlag,
    "Senegal": senegalFlag,
    "Serbia": serbiaFlag,
    "South Korea": southFlag,
    "Spain": spainFlag,
    "Switzerland": switzerlandFlag,
    "Tunisia": tunisiaFlag,
    "Uruguay": uruguayFlag,
    "USA": usaFlag,
    "Wales": walesFlag,
}

const mappingData = ["Argentina", "Australia", "Belgium", "Brazil", "Cameroon", "Canada", "Costa Rica", "Croatia", "Denmark", "Ecuador", "England", "France", "Germany", "Ghana", "Iran", "Japan", "Mexico", "Morocco", "Netherlands", "Poland", "Portugal", "Qatar", "Saudi Arabia", "Senegal", "Serbia", "South Korea", "Spain", "Switzerland", "Tunisia", "Uruguay", "USA", "Wales"]
const flagPngs = [
    ':flag_ar:',
    ':flag_au:',
    ':flag_be:',
    ':flag_br:',
    ':flag_cm:',
    ':flag_ca:',
    ':flag_cr:',
    ':flag_hr:',
    ':flag_dk:',
    ':flag_ec:',
    ':england:',
    ':flag_fr:',
    ':flag_de:',
    ':flag_gh:',
    ':flag_ir:',
    ':flag_jp:',
    ':flag_mx:',
    ':flag_ma:',
    ':flag_nl:',
    ':flag_pl:',
    ':flag_pt:',
    ':flag_qa:',
    ':flag_sa:',
    ':flag_sn:',
    ':flag_rs:',
    ':flag_kr:',
    ':flag_ea:',
    ':flag_ch:',
    ':flag_tn:',
    ':flag_uy:',
    ':flag_us:',
    ':wales:'
];
const flagUrls = ['https://gateway.pinata.cloud/ipfs/QmYVdDrozdkbvXsJ5qgsNMheujhkkwbftHhChiyZAsVRgF',
    'https://gateway.pinata.cloud/ipfs/QmYa4XtQ64XJYvtBo1BfMiwTRFN2G7of98YRpZse6VBs61',
    'https://gateway.pinata.cloud/ipfs/QmRWb4UyehgCEsy9jGxxydqMsCfiQcZaJXPDRRhM1MMRGg',
    'https://gateway.pinata.cloud/ipfs/QmTM9c4ATJfRKmAE25xiyQQMvZQ4ZBYQmpL2G3KLdCUH4K',
    'https://gateway.pinata.cloud/ipfs/QmSfzt11zU9Ggjnoehqm3ExKoFFx2Qh9AxBNkYpmsFzPdZ',
    'https://gateway.pinata.cloud/ipfs/QmQYGGgAqnSa3sZ5z1QVJZtYW8ApBHQduGYVqYUs9Q3sCX',
    'https://gateway.pinata.cloud/ipfs/QmVFhwJvLGfYBgPBTXpkbMwfBWYpFRtDis4yfiHMsNiLGv',
    'https://gateway.pinata.cloud/ipfs/QmXP8H4aCWXcAG6hzZbensStErAhE7s3Hy8gSBUR5BD4Wq',
    'https://gateway.pinata.cloud/ipfs/QmaqF8xC5S6nDZRRSVvEkvwvpLaWUQ3kWX1XpMiy2beFmz',
    'https://gateway.pinata.cloud/ipfs/QmcJboeqKkwQryFzX5vuZx7ZQc2K7NHDCHzN4AUbsPptbM',
    'https://gateway.pinata.cloud/ipfs/QmThk1CLbxgig9JKFAEgdtCr96jEPskVM8gpnzpc9x96oD',
    'https://gateway.pinata.cloud/ipfs/QmVMwuyyh5BofHKgEqQf3gusEWzv6sNhHs1MSejpRJSJfG',
    'https://gateway.pinata.cloud/ipfs/QmSdULamhMxnq3CveoKYmTAdsFvCpUz1eJ6qwbg6X9xYUx',
    'https://gateway.pinata.cloud/ipfs/QmdG7F3AndJMWKBZafiL17b2LNwrPfQssE8RE5zEQgvXqX',
    'https://gateway.pinata.cloud/ipfs/QmeoVFL5wkTt2id27hPooqRtuKqaspxAcyKqqaU7dqTZjC',
    'https://gateway.pinata.cloud/ipfs/QmRnwkg487Y3MfBbMm8sorVjuWL5iLE3hc7vFfVWTAjqVB',
    'https://gateway.pinata.cloud/ipfs/QmRLEFybm5PDXiUbbfSPjJfaPG7cK8cwFL89vEqzwSxhiG',
    'https://gateway.pinata.cloud/ipfs/QmNoKX6QWY4JwWxzptWo2p4mVoGeV2fE8wXYYXsxgz3PJ8',
    'https://gateway.pinata.cloud/ipfs/QmUUyDLcyUJRhBVKV8q7b4dpB6imLDYDHX2JAPaNSVuSWU',
    'https://gateway.pinata.cloud/ipfs/QmacD8So2s88YNEibpd1sD37JogWkw4wbb5SdbQN9psQQg',
    'https://gateway.pinata.cloud/ipfs/QmVPExHoh121tKJnBGTRkcK7XA8sbz4Fp1tBZcGAMhSdMz',
    'https://gateway.pinata.cloud/ipfs/QmRrmLrWsQrZ9XmPR9NdqT9D47y3MiWoo4s5kZn4DmjinY',
    'https://gateway.pinata.cloud/ipfs/QmSsHFusYjRdm3GeL3Dqr5UhD3xGsT4wD1ept3LZDysaUk',
    'https://gateway.pinata.cloud/ipfs/QmScdQ5vSxSFL3tRzU3fLhaY9XymCiJ12GjsZPbuDQcnqS',
    'https://gateway.pinata.cloud/ipfs/QmS2Zk4Rnkh6REWyR744nJoaCnGAo8FnWPgpggaJ1Pkwah',
    'https://gateway.pinata.cloud/ipfs/QmXiMdqwVj6b1JZHmEZ2wfqAFd9Yix6YTF964ydJn4MtnL',
    'https://gateway.pinata.cloud/ipfs/QmW8d5nYz8CyEMFfuXKQqUMzdwodrHw3rAtFAuVmG9sGWJ',
    'https://gateway.pinata.cloud/ipfs/QmfVomU1gcsKMpPrQFxtiBootWioQHuK7WpjMNqnGvsiW3',
    'https://gateway.pinata.cloud/ipfs/QmQZZQajJDpue1GKFB9ri1fE6nPJ3f4GSVDZJzgXCPnvqx',
    'https://gateway.pinata.cloud/ipfs/QmX8VjKb5gf9FCQdKZwUAYUZB1QL3xPqDJ7NLhcNVLMHXv',
    'https://gateway.pinata.cloud/ipfs/QmYaZLyeEfD5wtVzAX3TmUqfS8V7xEDQ5sxaQMTV15AmqG',
    'https://gateway.pinata.cloud/ipfs/QmNucD85eEt5cnrZnqNqYkyqxLd17NoN32e6M6q25SP3ic'
]
export {
    flags, mappingData, flagPngs, flagUrls
}