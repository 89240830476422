import React from 'react';
import {createSlice} from '@reduxjs/toolkit'

interface MatchDataState {
    eventList: any[],
    standing: any[],
    h2h: any[],
    lineup: any[],
    stats: any[],
    liveEvents: any[],
    liveEventsAll: any,
    liveFixtures: any[],
    teamList: any[],
    teamResultsAll: any,
    injuries: any
}

const initialState = {
    eventList: [],
    standing: [],
    h2h: [],
    lineup: [],
    stats: [],
    liveEvents: [],
    liveEventsAll: {},
    liveFixtures: [],
    teamList: [],
    teamResultsAll: [],
    injuries:[]
} as MatchDataState

const matchDataSlice = createSlice({
    name: 'FirebaseData',
    initialState,
    reducers: {
        setEventList(state, action) {
            state.eventList = action.payload
        },
        setStanding(state, action) {
            state.standing = action.payload
        },
        setH2h(state, action) {
            state.h2h = action.payload
        },
        setLineup(state, action) {
            state.lineup = action.payload
        },
        setStats(state, action) {
            state.stats = action.payload
        },
        setLiveEvents(state, action) {
            state.liveEvents = action.payload
        },
        setLiveEventsAll(state, action) {
            state.liveEventsAll = action.payload
        },
        setLiveFixtures(state, action) {
            state.liveFixtures = action.payload
        },
        setTeamList(state, action) {
            state.teamList = action.payload
        },
        setTeamResultsAll(state, action) {
            state.teamResultsAll = action.payload
        },
        setInjuries(state, action) {
          state.injuries = action.payload
        }
    },
})

export const {
    setEventList,
    setStanding,
    setH2h,
    setLineup,
    setStats,
    setLiveEvents,
    setLiveEventsAll,
    setLiveFixtures,
    setTeamList,
    setTeamResultsAll,
    setInjuries
} = matchDataSlice.actions
export default matchDataSlice.reducer