import {useAccount, useContractWrite, usePrepareContractWrite, useWaitForTransaction} from "wagmi";
import {mint_addr} from "../../services/link.config";
import {show} from "../../redux/reducer/notification";
import {create} from 'apisauce';
import {useAppDispatch, useAppSelector} from "../../hooks";
import flashIcon from "../../assets/flash.svg";
import ScaleLoader from "react-spinners/ScaleLoader";
import {utils} from "ethers";
import {setLoading} from "../../redux/reducer/wallet";
import * as React from 'react'
import {useEffect, useMemo} from "react";
import {setLoadingStatus, setWidgetItems, setWidgetStats} from "../../redux/reducer/widget";
import {flagPngs, flagUrls, mappingData} from "../AllTabContent/Flags";
import {deleteDoc, doc} from "firebase/firestore";
import db from "../../services/firestore.config";

const useMyhook = ({ids, amounts, address, totalPrice}) => {
    const {
        config,
        error: prepareError,
        isError: isPrepareError
    } = usePrepareContractWrite({
        address: mint_addr,
        abi: [{
            "inputs": [
                {
                    "internalType": "uint256[]",
                    "name": "_ids",
                    "type": "uint256[]"
                },
                {
                    "internalType": "uint256[]",
                    "name": "quantities",
                    "type": "uint256[]"
                }
            ],
            "name": "batchMint",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        }],
        functionName: 'batchMint',
        args: [ids, amounts],
        overrides: {
            from: address,
            value: utils.parseEther(totalPrice.toString())
        }
    })

    const {data, error, isError, write} = useContractWrite(config)

    return {data, error, isError, write, prepareError, isPrepareError}
}

export const MintNFT = () => {
    const dispatch = useAppDispatch();
    const totalPrice = useAppSelector(state => state.widget.totalPrice);
    const Items = useAppSelector(state => state.widget.items);
    const loading = useAppSelector(state => state.wallet.loading);
    const balance = useAppSelector(state => state.wallet.walletAmount);
    let ids = [];
    let amounts = [];
    let embeds = [];

    const {address} = useAccount();
//1044913326934741052/80RlBu40O5JVG9Ec5YRFhZeHbJ8A9KBJJm3PR8_Yo9jYcgcdzHyCv9QXdR8m5QIDNH3i
    const api = create({
        baseURL: 'https://discord.com/api/webhooks/1044913326934741052/80RlBu40O5JVG9Ec5YRFhZeHbJ8A9KBJJm3PR8_Yo9jYcgcdzHyCv9QXdR8m5QIDNH3i',
        headers: {
            'Content-type': 'application/json'
        }
    })
    const items = (amounts) => {
        if (amounts > 1) {
            return " x" + amounts;
        } else {
            return ""
        }
    }
    const valid_s = (amounts) => {
        if (amounts > 1) {
            return "s";
        } else {
            return ""
        }
    }

    Object.keys(Items).forEach(key => {
        ids.push(key);
        amounts.push(Items[key]);
        embeds.push({
            "username": "Sportspredict.io",
            "avatar_url": "https://i.ibb.co/5KVDPqG/Group-42.png",
            "embeds": [{
                "color": 1127128,
                "description": "[🎉  ⚽  " + address.substr(0, 5) + "...." + address.substr(-5) + " minted new Prediction NFT" + valid_s(Items[key]) + "](https://opensea.io/assets/MATIC/0xfa73c01e9df6bebcba5487dca4c22b870688fa20/" + key + ") \n \n **" + mappingData[key] + " " + flagPngs[key] + items(Number(Items[key])) + "**",
                "image": {
                    "url": flagUrls[key]
                }
            }]
        })
    })

    const {data, error, isError, write, isPrepareError, prepareError} = useMyhook({ids, amounts, address, totalPrice})

    const {isLoading, isSuccess} = useWaitForTransaction({
        hash: data?.hash,
    })

    useEffect(() => {
        if (isError) {
            dispatch(show({title: "Transaction Failed", body: '', type: 'error'}));
            dispatch(setLoading(false));
        }
        if (isSuccess) {
            deleteDoc(doc(db, 'cart', address));
            dispatch(setWidgetItems({}));
            dispatch(setWidgetStats(false));
            embeds.forEach(embed => {
                api.post('', embed).then(
                    res => {
                        console.log('notification', res);
                    }
                );
            })
            dispatch(setLoadingStatus(true));
            dispatch(show({title: "Transaction Completed", body: '', type: 'success'}));
            dispatch(setLoading(false));
        }
        if (isPrepareError) {
            dispatch(show({
                title: "Insufficient funds, please check your balance or remove some NFTs",
                body: '',
                type: 'success'
            }));
            dispatch(setLoading(false));
        }
    }, [isError, isSuccess]);

    useEffect(() => {
        if (loading) {

            if (totalPrice >= balance) {
                dispatch(show({title: "Insufficient Balance", body: '', type: 'error'}));
                dispatch(setLoading(false));
            }
        }
    }, [loading])

    return (
        <button onClick={() => {
            write?.();
            dispatch(setLoading(true));
        }} data-modal-toggle="defaultModal" type="button"
                disabled={Object.keys(Items).length < 1 || loading}
                className={`${Object.keys(Items).length > 0 || loading ? 'bg-[#3EB489]' : 'bg-[#2D2730]'} text-white flex flex-row gap-x-2 w-full h-12 items-center text-lg rounded-2xl text-center justify-center font-semibold`}>
            <img src={flashIcon} className={'w-5'} alt={'flash-icon'}/> Confirm Mint
            <ScaleLoader color={'#fff'} loading={loading} height={22} width={2}/>
        </button>
    )
}