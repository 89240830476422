import React from 'react';
import db from './firestore.config';
import {doc, getDoc} from "firebase/firestore";
import {useAppDispatch} from "../hooks";
import {
    setFinalStage,
    setGroupStage,
    setPriceFeed,
    setQuarterStage,
    setRound16Stage,
    setSemiStage
} from "../redux/reducer/widget";

export const useEliminateMange = () => {
    const dispatch = useAppDispatch();

    const useGroupStage = async () => {
        const groupRef = doc(db, 'stages', 'group-stage');
        const groupSnap = await getDoc(groupRef);

        try {
            if (groupSnap.exists()) {
                dispatch(setGroupStage(groupSnap.data()));
            } else {
                console.log("group stage - ", []);
            }
        } catch (e) {
            console.log("group stage error", e);
        }
    }

    const useRoundOf16 = async () => {
        const groupRef = doc(db, 'stages', 'round16-stage');
        const groupSnap = await getDoc(groupRef);

        try {
            if (groupSnap.exists()) {
                dispatch(setRound16Stage(groupSnap.data()));
            } else {
                console.log("round of 16 stage", []);
            }
        } catch (e) {
            console.log("round of 16 error", e);
        }
    }

    const useQuarterFinal = async () => {
        const groupRef = doc(db, 'stages', 'quarter-final-stage');
        const groupSnap = await getDoc(groupRef);

        try {
            if (groupSnap.exists()) {
                dispatch(setQuarterStage(groupSnap.data()));
            } else {
                console.log("quarter final stage", []);
            }
        } catch (e) {
            console.log("quarter final stage error", e);
        }
    }

    const useSemiFinal = async () => {
        const groupRef = doc(db, 'stages', 'semi-final-stage');
        const groupSnap = await getDoc(groupRef);

        try {
            if (groupSnap.exists()) {
                dispatch(setSemiStage(groupSnap.data()));
            } else {
                console.log("semi final", {});
            }
        } catch (e) {
            console.log("semi final error", e);
        }
    }

    const useFinal = async () => {
        const groupRef = doc(db, 'stages', 'final-stage');
        const groupSnap = await getDoc(groupRef);

        try {
            if (groupSnap.exists()) {
                dispatch(setFinalStage(groupSnap.data()));
            } else {
                console.log("final stage", []);
            }
        } catch (e) {
            console.log("final error", e);
        }
    }

    const usePriceFeed = async () => {
        const feedRef = doc(db, 'stages', 'price-feed');
        const feedSnap = await getDoc(feedRef);
        try {
            if (feedSnap.exists()) {
                dispatch(setPriceFeed(feedSnap.data()));
            } else {
                console.log("final stage", []);
            }
        } catch (e) {
            console.log("final error", e);
        }
    }

    return {
        useGroupStage, useRoundOf16, useQuarterFinal, useSemiFinal, useFinal, usePriceFeed
    }
}