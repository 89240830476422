import {useEffect} from "react";
import {AllTabContent} from "../Components/AllTabContent/AllTabContent";
import {MainTabs} from "../Components/MainTabs/MainTabs";
import {Element} from "../Components/ReactScroll";
import "./Home.css";
import {Navbar} from "../Components/Navbar/Navbar";
import {PrizePool} from "../Components/PrizePool/PrizePool";
import {useAppDispatch, useAppSelector} from "../hooks";
import {Cart} from "../Components/Cart/Cart";
import {SocialTab} from "../Components/SocialTab/SocialTab";
import {PrizeCart} from "../Components/Cart/PrizeCart";
import {mint_addr} from "../services/link.config";
import {setPurchasedNFTs, setTotalPrice} from "../redux/reducer/prizeCart";

export const Home = () => {
    const dispatch = useAppDispatch();
    const widgetStatus = useAppSelector(state => state.widget.stats);
    const prizeCartStatus = useAppSelector(state => state.prizeCart.state);
    const ownedNFTs = useAppSelector(state => state.wallet.ownedNFTs);
    const mintData = useAppSelector(state => state.wallet.getMintData);
    const totalSupply = useAppSelector(state => state.wallet.totalSupply);
    const priceFeed = useAppSelector(state => state.widget.priceFeed);
    const divisions = [0.5, 0.175, 0.1, 0.05, 0.04, 0.025, 0.01];
    const divisionTeams = [1, 1, 1, 1, 4, 8, 16];

    const rewardAmount = () => {
        let prizeAmount = 0;
        let purchasedNFTs = [];
        if (ownedNFTs.length > 0) {
            for (let nft of ownedNFTs) {
                if (nft.contract.address.toLowerCase() == mint_addr.toLowerCase()) {
                    const nftAmounts = nft.balance;
                    const totalSupplyPerId = totalSupply[nft.tokenId];
                    const totalPool = mintData[1];
                    purchasedNFTs.push(nft);
                    if (priceFeed[nft.tokenId] > -1) {
                        const divisionLevel = divisions[priceFeed[nft.tokenId]];
                        prizeAmount += totalPool * divisionLevel * nftAmounts / (totalSupplyPerId * divisionTeams[priceFeed[nft.tokenId]]);
                    }
                }
            }
            dispatch(setPurchasedNFTs(purchasedNFTs));
            dispatch(setTotalPrice(prizeAmount));
            return prizeAmount;
        } else {
            return 0
        }
    }
    return (
        <Element name="details" className="element">

            <div className={`poppin-text py-5 px-5 sm:px-10 2xl:px-20 ${(widgetStatus || prizeCartStatus) && 'blur-sm'}`}>
                <Navbar/>
                <div className="mt-10 lg:mt-0">
                    <PrizePool rewardAmount={rewardAmount}/>
                    <MainTabs/>
                    <div className="mt-2 mb-20">
                        <AllTabContent/>
                    </div>
                    <SocialTab/>
                </div>
                {/*<Footer/>*/}
            </div>
            {widgetStatus ? (
                <Cart/>
            ) : null}

            {prizeCartStatus ? (
                <PrizeCart rewardAmount={rewardAmount}/>
            ) : null}

        </Element>
    );
};
