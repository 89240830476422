import React from 'react';
import {createSlice} from '@reduxjs/toolkit'

interface WalletState {
    connected: any[],
    usdcBalance: number,
    goerliBalance: number,
    maticBalance: number,
    walletAddress: string,
    walletAmount: number,
    getMintData: any[],
    getTokenPrice: any,
    cartTotalPrice: number,
    totalSupply: any[],
    supplyByUser: any[],
    loading: boolean,
    maticPrice: number,
    holders: number,
    ownedNFTs: any,
    getRewardData: any[],
    approved: boolean
}

const initialState = {
    connected: [],
    usdcBalance: 0,
    goerliBalance: 0,
    maticBalance: 0,
    walletAddress: '',
    walletAmount: 0,
    getMintData: [],
    getTokenPrice: [],
    cartTotalPrice: 0,
    totalSupply: [],
    supplyByUser: [],
    loading: false,
    maticPrice: 0,
    holders: 0,
    ownedNFTs: {},
    getRewardData: [],
    approved: false
} as WalletState

const walletSlice = createSlice({
    name: 'wallet connect',
    initialState,
    reducers: {
        setConnectEvent(state, action) {
            state.connected = action.payload
        },
        setUsdcBalance(state, action) {
            state.usdcBalance = action.payload;
        },
        setGoerliBalance(state, action) {
            state.goerliBalance = action.payload;
        },
        setMaticBalance(state, action) {
            state.maticBalance = action.payload;
        },
        setWalletAddress(state, action) {
            state.walletAddress = action.payload;
        },
        setWalletAmount(state, action) {
            state.walletAmount = action.payload;
        },
        setMintData(state, action) {
            state.getMintData = action.payload;
        },
        setTokenPrice(state, action) {
            state.getTokenPrice = action.payload;
        },
        setCartTotalPrice(state, action) {
            state.cartTotalPrice = action.payload;
        },
        setTotalSupply(state, action) {
            state.totalSupply = action.payload;
        },
        setSupplyByUser(state, action) {
            state.supplyByUser = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setMaticPrice(state, action) {
            state.maticPrice = action.payload;
        },
        setHolders(state, action) {
            state.holders = action.payload;
        },
        setOwnedNFTs(state, action) {
            state.ownedNFTs = action.payload;
        },
        setRewardData(state, action) {
            state.getRewardData = action.payload;
        },
        setApproved(state, action) {
            state.approved = action.payload;
        }
    },
})

export const {
    setConnectEvent,
    setUsdcBalance,
    setGoerliBalance,
    setMaticBalance,
    setWalletAddress,
    setMintData,
    setTokenPrice,
    setCartTotalPrice,
    setWalletAmount,
    setTotalSupply,
    setSupplyByUser,
    setLoading,
    setMaticPrice,
    setHolders,
    setOwnedNFTs,
    setRewardData,
    setApproved
} = walletSlice.actions
export default walletSlice.reducer