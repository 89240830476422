import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./redux/store";

//wallet connect - rainbowkit
import '@rainbow-me/rainbowkit/styles.css';
import {
    getDefaultWallets,
    RainbowKitProvider,
    darkTheme,
} from '@rainbow-me/rainbowkit';
import {
    chain,
    configureChains,
    createClient,
    WagmiConfig,
    useBalance
} from 'wagmi';
import {alchemyProvider} from 'wagmi/providers/alchemy';
import {publicProvider} from 'wagmi/providers/public';
import {alchemy_id} from "./services/link.config";

const {chains, provider} = configureChains(
    [chain.polygon],// chain.polygon
    [
        alchemyProvider({apiKey: alchemy_id}),
        publicProvider()
    ]
);
const {connectors} = getDefaultWallets({
    appName: 'World Cup Dashboard',
    chains
});
const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} modalSize="compact" theme={darkTheme({
            accentColor: '#007AFF',
            accentColorForeground: 'white',
            borderRadius: 'large',
            overlayBlur: 'small',
        })} showRecentTransactions={true}>
            <React.StrictMode>
                <Provider store={store}>
                    <App/>
                </Provider>
            </React.StrictMode>
        </RainbowKitProvider>
    </WagmiConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
